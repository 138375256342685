import React, { PropsWithChildren } from 'react';
import { Spinner } from '@fluentui/react-components';
import styles from './Loading.module.css';
export interface LoadingProps {}

const Loading = (props: PropsWithChildren<LoadingProps>) => {
  return (
    <div className={styles.container}>
      <div>
        {props.children}
        <Spinner size="huge" />
      </div>
    </div>
  );
};

export default Loading;
