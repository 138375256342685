import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState, AppDispatch } from '../../../store/store';
import { setChatMessages, setDraftResponseChunk, setIsDocumentSessionsStarted, setIsEssentialInformationComplete, setIsGeneratingDraft, setIsLoadingListMandatoryQuestionsSubset } from '../../../store/draftSlice';
import { sendMandatoryQuestionsSubsetRequest } from '../../../store/draftSlice';
import { setReviewStepNumber, setViewHeader } from '../../../store/reviewSlice';
import { MessageRole } from '../../../models/chat';
import { ChatMessage } from '../../../models/chat';
import BotChating from '../../shared/botChating/BotChating';
import { DraftDocumentResponseChunk } from '../../../models/draft';
import { Toast, ToastTitle, useOptionStyles_unstable, useToastController } from '@fluentui/react-components';
import { GLOBAL_TOAST_ID } from '../../layout/Layout';
import { checkDocumentHasContent, clearSuggestions, loadWordDocument, setActionsTab, setIsAnalyzing, setPreventAnalyzing } from '../../../store/officeSlice';
import { ActionsTabs, ReviewSteps } from '../review/components/const';
import { sendMessage } from '../../../store/socketSlice';
import { clearDocumentContent } from '../../../helper/office';
import { ResponseEnum, StatusEnum } from '../../../models/base';

enum YesOptionEnum {
  GENERATE_AGAIN = 'GENERATE_AGAIN',
  INITAL_QUESTIONS_DONE = 'INITAL_QUESTIONS_DONE',
}

enum NoOptionEnum {
  GENERATE_AGAIN = 'GENERATE_AGAIN',
  INITAL_QUESTIONS_DONE = 'INITAL_QUESTIONS_DONE',
}

const DraftChat = ({sendPayload}) => {
  const [value, setValue] = useState('');
  const currentSequence = useRef<number>(2);
  const currentQuestionSet = useRef<number>(1);
  const [captureLastUserResponse, setCaptureLastUserResponse] =
    useState<string>('');
  const [isCapturedLastUserResponse, setIsCapturedLastUserResponse] =
    useState<boolean>(false);
  const [isAllowedToShiftToModifications, setIsAllowedTOShiftToModifications] = useState<boolean>(false);
  const [probabilityValue, setProbabilityValue] = useState(0);
  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);
  const [yesQuestion, setYesQuestion] = useState<YesOptionEnum>();
  const [noQuestion, setNoQuestion] = useState<NoOptionEnum>();

  const dispatch = useDispatch<AppDispatch>();
  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const mandatoryQuestions = useSelector(
    (state: RootState) => state.draft.mandatoryQuestionsSubset
  );
  const isLoadingListMandatoryQuestions: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingListMandatoryQuestionsSubset
  );

  const isGeneratingDraft: boolean = useSelector(
    (state: RootState) => state.draft.isGeneratingDraft
  );

  const draftChunkResponse: DraftDocumentResponseChunk = useSelector(
    (state: RootState) => state.draft.draftChunkResponse
  );
  const documentId = useSelector((state: RootState) => state.office.documentId);
  const isDocumentSessionStarted = useSelector((state: RootState) => state.draft.isDocumentSessionStarted);
  const calculateProgressProbability = (current: number, total: number) => {
    return current / total;
  }
  
  // const reviewDocument = () => {
  //   dispatch(setPreventAnalyzing(false));
  //   dispatch(setIsAnalyzing(true));
  //   dispatch(clearSuggestions());
  //   dispatch(
  //     sendMessage({
  //       action: 'DocumentReviewRequestV2',
  //       user_document_id: documentId,
  //     })
  //   );
  // };
  
  const initalDraftChunkResponse: DraftDocumentResponseChunk = {
    action: ResponseEnum.DraftDocumentResponseChunk,
    status: StatusEnum.Success,
    errorMessage: null,
    response: "",
    document_text: "",
    chunk_index: 1,
    total_chunks: 10,
    is_complete: false
  }



  const onYes = async() => {
      switch (yesQuestion) {
        case YesOptionEnum.GENERATE_AGAIN:
          await clearDocumentContent();
          sendPayload()
          break;
        default:
          const message = 'We are done with the draft generation process!';
          const botMessage: ChatMessage = {
            message: message,
            role: MessageRole.Assistant,
            id: uuidv4(),
            isChunk: false,
            attached: '',
          };
          dispatch(setChatMessages([...chatMessages, botMessage]));
          break;
      }
  }
  
  const onNo = async() => {
    switch(noQuestion)
    {
        default:
          const message = 'We are done with the draft generation process!';
          const botMessage: ChatMessage = {
            message: message,
            role: MessageRole.Assistant,
            id: uuidv4(),
            isChunk: false,
            attached: '',
          };
          dispatch(setChatMessages([...chatMessages, botMessage]));
          break;
    }
  }

  useEffect(() => {
    const fetchInitialQuestion = async () => {
      if(chatMessages.length == 0)
      {
        const initialQuestion = 'Hi, how can I help you with your drafting needs?';
        const botMessage: ChatMessage = {
          message: initialQuestion,
          role: MessageRole.Assistant,
          id: uuidv4(),
          isChunk: false,
          attached: '',
        };
        dispatch(setChatMessages([...chatMessages, botMessage]));
      }
    };
    fetchInitialQuestion();
  }, [chatMessages]);

  useEffect(() => {
    const updateOnResponse = async() => {
      if(isGeneratingDraft == true && draftChunkResponse)
      {
        const probabilityValue = calculateProgressProbability(draftChunkResponse.chunk_index, draftChunkResponse.total_chunks);
        setProbabilityValue(probabilityValue);
      }
      else if(isGeneratingDraft == false)
      {
        dispatchToast(
          <Toast>
            <ToastTitle>Draft Generated Successfully!</ToastTitle>
          </Toast>,
          { position: 'bottom-end', intent: 'success' }
        );
        var messageForDrafting = "Draft Generation Complete.";
        var botMessage: ChatMessage = {
          message: messageForDrafting,
          role: MessageRole.Assistant,
          id: uuidv4(),
          isChunk: false,
          attached: '',
        };
        dispatch(setChatMessages([...chatMessages, botMessage]));
        dispatch(setIsGeneratingDraft(null));
        currentSequence.current = 4;
        await getMandatoryQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, mandatoryQuestions.retained_info);
        dispatch(setDraftResponseChunk(initalDraftChunkResponse));
      }
    }
    updateOnResponse()
  }, [draftChunkResponse])
  
  // const incrementQuestionSet = async() => {
  //   currentQuestionSet.current += 1;
  //   await getMandatoryQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, mandatoryQuestions.retained_info);
  // }
  
  useEffect(() => {
    if (
      !isLoadingListMandatoryQuestions &&
      mandatoryQuestions?.question != null
    ) {
      console.log("These are the mandaotry questions: ", mandatoryQuestions);
      const is_yes_no = mandatoryQuestions.type === 'yes_no';
      const botMessage: ChatMessage = {
        message: mandatoryQuestions.question,
        role: MessageRole.Assistant,
        id: uuidv4(),
        isChunk: false,
        is_yes_no: is_yes_no,
        attached: '',
      };
      if(is_yes_no){
        setYesQuestion(YesOptionEnum[mandatoryQuestions.subtype as keyof typeof YesOptionEnum]);
        setNoQuestion(NoOptionEnum[mandatoryQuestions.subtype as keyof typeof NoOptionEnum]);
      }
      currentSequence.current = mandatoryQuestions.current_sequence;
      dispatch(setChatMessages([...chatMessages, botMessage]));
    }
  }, [isLoadingListMandatoryQuestions]);
  
  const handleMandatoryQuestionsChange = () => {
    const retainedInfo = mandatoryQuestions?.retained_info ? JSON.parse(JSON.stringify(mandatoryQuestions.retained_info)) : {};
    const isKeyInformationComplete = verifyKeyInformation(retainedInfo);
    if (isKeyInformationComplete) {
      dispatch(setIsEssentialInformationComplete(true));
    } else {
      dispatch(setIsEssentialInformationComplete(false));
    }
  };

  useEffect(() => {
    handleMandatoryQuestionsChange();
  }, [mandatoryQuestions]);
  
  const verifyKeyInformation = (retainedInfo) => {
    if(!isLoadingListMandatoryQuestions)
    {
        if (Object.keys(retainedInfo).length === 0) {
          return false;
        }
        for (const key in retainedInfo) {
          if (retainedInfo.hasOwnProperty(key)) {
            console.log("This is the key", retainedInfo[key]);
            if (retainedInfo[key] === "" || retainedInfo[key] === null || retainedInfo[key] === undefined) {
              return false;
            }
          }
        }
        return true;
    }
    else
    {
      return false;
    }
  }

  const onMessageSend = async () => {
    if (isConnected && value.trim() && !isLoadingListMandatoryQuestions) {
      const userMessage: ChatMessage = {
        message: value,
        role: MessageRole.User,
        id: uuidv4(),
        isChunk: false,
        attached: '',
      };
      dispatch(setChatMessages([...chatMessages, userMessage]));
      setValue('');
      const retainedInfo = mandatoryQuestions?.retained_info ? JSON.parse(JSON.stringify(mandatoryQuestions.retained_info)) : {};
      await getMandatoryQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, retainedInfo);
      const isKeyInformationComplete = verifyKeyInformation(retainedInfo)
      if(isKeyInformationComplete)
      {
        dispatch(setIsEssentialInformationComplete(true))
      }
      else
      {
        dispatch(setIsEssentialInformationComplete(false))
        return;
      }
      // if (mandatoryQuestions.is_end && isCapturedLastUserResponse) {
      //   setIsChatDisabled(true);
      //   // TODO: Add logic to generate draft
      //   // TODO: Dispatch action to set hasGeneratedDraft to true
      //   const payload: SendDraftRequestPayload = {
      //     template: null,
      //     chat_history: chatMessages
      //   };
      //   dispatch(sendDraftRequest(payload));
      // } else if (mandatoryQuestions.is_end && !isCapturedLastUserResponse) {
      //   setCaptureLastUserResponse(value);
      //   setIsCapturedLastUserResponse(true);
      // }
    }
  };

  const getMandatoryQuestionsInSubset = async (
    question_id: number,
    current_sequence: number,
    earlier_user_response: string,
    retained_info: any
  ) => {
    const resultAction = await dispatch(
      sendMandatoryQuestionsSubsetRequest({
        question_id,
        current_sequence,
        earlier_user_response,
        retained_info,
      })
    );
    if (sendMandatoryQuestionsSubsetRequest.fulfilled.match(resultAction)) {
      console.log('Mandatory Questions:', mandatoryQuestions);
    } else {
      console.error('Failed to get mandatory questions:', resultAction.payload);
    }
  };

  useEffect(() => {
    dispatch(setViewHeader(true));
  }, []);

  return (
    <div>
      <BotChating
        loading={isLoadingListMandatoryQuestions || isGeneratingDraft}
        onYes={onYes}
        onNo={onNo}
        onMessageSend={onMessageSend}
        isShowProgressBar={isGeneratingDraft}
        progressBarValue={probabilityValue}
        isShowProgressBarLabel={true}
        progressBarLabel={'Generating Draft... ' + (probabilityValue * 100).toFixed(0) + '%'}
        chatMessages={chatMessages}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default DraftChat;
