// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mPc4TvXK3aRfF_9aeC32 {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  justify-content: center;\n  align-items: center;\n  font-weight: 400;\n  font-size: 0.75rem;\n  color: #626262;\n}\n.CZ3eCZXs3Zra9G2jSzlo {\n  display: flex;\n  flex-direction: row;\n  gap: 0.2rem;\n  justify-content: space-between;\n  align-items: center;\n  width: 90%;\n}\n.Fe0H0OzY5IzQ9aHfAfw4 {\n  margin-right: 0.5rem;\n  background-color: #0387f5;\n}\n.sWaqhMPitl0EZ7ov9g2d {\n  margin-right: 1.5rem;\n}\n.wa6_KzZhXTjvqUqUx2I6 {\n  font-size: 10px;\n  color: rgba(98, 98, 98, 1);\n}\n.g43nn8A7m_xgVcAWYbpH {\n  display: flex;\n  gap: 2px;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/features/review/components/ReviewProgress.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,QAAQ;EACR,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  justify-content: center;\n  align-items: center;\n  font-weight: 400;\n  font-size: 0.75rem;\n  color: #626262;\n}\n.progressContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 0.2rem;\n  justify-content: space-between;\n  align-items: center;\n  width: 90%;\n}\n.progressBar {\n  margin-right: 0.5rem;\n  background-color: #0387f5;\n}\n.percentage {\n  margin-right: 1.5rem;\n}\n.progressHeader {\n  font-size: 10px;\n  color: rgba(98, 98, 98, 1);\n}\n.likeCnt {\n  display: flex;\n  gap: 2px;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "mPc4TvXK3aRfF_9aeC32",
	"progressContainer": "CZ3eCZXs3Zra9G2jSzlo",
	"progressBar": "Fe0H0OzY5IzQ9aHfAfw4",
	"percentage": "sWaqhMPitl0EZ7ov9g2d",
	"progressHeader": "wa6_KzZhXTjvqUqUx2I6",
	"likeCnt": "g43nn8A7m_xgVcAWYbpH"
};
export default ___CSS_LOADER_EXPORT___;
