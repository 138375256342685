import { combineReducers } from 'redux';
import socketReducer, { initSocket } from './socketSlice';
import officeReducer, { setIsAnalyzing } from './officeSlice';
import annotationReducer from './annotationSlice';
import draftReducer from './draftSlice';
import activityReducer from './activitySlice';
// hack to get working without end
import reviewChatReducer from './reviewSlice';
import documentReducer from './documentSlice';
const rootReducer = combineReducers({
  socket: socketReducer,
  office: officeReducer,
  draft: draftReducer,
  annotation: annotationReducer,
  activity: activityReducer,
  review: reviewChatReducer,
  document: documentReducer,
  // Add other reducers as needed
});
export const initApp = () => async dispatch => {
  // production - to work with backend
  dispatch(initSocket());

  // different route to work on screens without having to worry about talking to backend
  // dispatch(setIsAnalyzing(false));
  // Add other initialization logic here
};
export default rootReducer;
