import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { GetAccountMgmt } from '../../../store/socketSlice';
import { PersonRegular } from '@fluentui/react-icons';
import styles from './HomeHeader.module.css';
import { Image, Tooltip } from '@fluentui/react-components';
import { RequestEnum } from '../../../models/base';
import { HomeTabs } from '../../features/review/components/const';
import { setActionsTab, setCurrentTab } from '../../../store/officeSlice';

const HomeHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isAccountOpen, setIsAccountOpen] = React.useState(false);
  const currentTab = useSelector((state: RootState) => state.office.currentTab);
  const accountRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const accountMgmt = useSelector(
    (state: RootState) => state.socket.accountMgmt
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );

  const handleClickOutside = event => {
    if (
      accountRef.current &&
      buttonRef.current &&
      !accountRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsAccountOpen(false);
    }
  };

  const handleSignOutApp = () => {
    console.log('sign out of app');
    localStorage.removeItem('q_token');
    localStorage.removeItem('q_refresh');
    localStorage.removeItem('q_id');
    dispatch(
      GetAccountMgmt({
        action: RequestEnum.GetAccountMgmtRequest,
        account_request: 'sign out of app',
      })
    );

    window.location.reload();
  };

  const handleSignOutAll = () => {
    console.log('sign out of all');
    localStorage.removeItem('q_token');
    localStorage.removeItem('q_refresh');
    localStorage.removeItem('q_id');
    dispatch(
      GetAccountMgmt({
        action: RequestEnum.GetAccountMgmtRequest,
        account_request: 'sign out of all',
      })
    );
    window.location.reload();
  };
  const handleAccountButtonClick = () => {
    setIsAccountOpen(prev => !prev);
    if (!isConnected) {
      console.log(
        'account clicked when no connection, clearing tokens to force sign in'
      );
      localStorage.removeItem('q_token');
      localStorage.removeItem('q_refresh');
      localStorage.removeItem('q_id');
      window.location.reload();
    }
    console.log(isAccountOpen);
  };

  //-------------------------------------------------------------------------------
  useEffect(() => {
    if (accountMgmt !== undefined) {
      console.log('Account Info Response:', accountMgmt.account_email);
    }
  }, [accountMgmt]);

  useEffect(() => {
    if (isAccountOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAccountOpen]);
  useEffect(() => {
    if (isAccountOpen && isConnected) {
      dispatch(
        GetAccountMgmt({
          action: RequestEnum.GetAccountMgmtRequest,
          account_request: 'account info request',
        })
      );
    }
  }, [isConnected, isAccountOpen]);
  const viewHeader = useSelector((state: RootState) => state.review.viewHeader);

  //-------------------------------------------------------------------------

  return (
    <div className={styles.mainHeader}>
      <div className={styles.firstPart}>
        <div className={styles.iconName}>
          <Image src="/assets/logo-white.png"></Image>
          <div className={styles.pluginName}>Qanooni</div>
        </div>
        <div className={styles.pluginIcons}>
          <Tooltip content="Account Management" relationship="label">
            <button
              type="button"
              title="user button"
              ref={buttonRef}
              className={styles.personalBtn}
              onClick={handleAccountButtonClick}
            >
              <Image src="/assets/white-profile.png"></Image>
            </button>
          </Tooltip>
          {isAccountOpen && accountMgmt && (
            <div ref={accountRef} className={styles.accountWindow}>
              <div className={styles.accountWindowContent}>
                <div className={styles.accountInfo}>
                  <b>{accountMgmt.account_email}</b>
                </div>
                <div className={styles.accountSignOutArea}>
                  <Tooltip
                    content="This will just sign you out of this app"
                    relationship="label"
                  >
                    <button
                      className={styles.accountSignOutButton}
                      title="logout button"
                      onClick={handleSignOutApp}
                    >
                      Sign Out of this App
                    </button>
                  </Tooltip>
                  <Tooltip
                    content="This will stop all mailbox processing"
                    relationship="label"
                  >
                    <button
                      className={styles.accountSignOutButton}
                      onClick={handleSignOutAll}
                      title="logout button"
                    >
                      Sign Out of all Qanooni
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}

          <div className={styles.helpIcon}>?</div>
        </div>
      </div>
      {viewHeader && (
        <div className={styles.secondPart}>
          <div
            className={
              currentTab == HomeTabs.Home
                ? styles.tabItemActive
                : styles.tabItem
            }
            onClick={() => dispatch(setCurrentTab(HomeTabs.Home))}
          >
            <Image
              src={`/assets/home${
                currentTab == HomeTabs.Home ? 'Blue' : ''
              }.svg`}
            ></Image>
            <div>Home</div>
          </div>
          <div
            className={
              currentTab == HomeTabs.Actions
                ? styles.tabItemActive
                : styles.tabItem
            }
            onClick={() => {
              dispatch(setCurrentTab(HomeTabs.Actions));
              dispatch(setActionsTab(undefined));
            }}
          >
            <Image
              src={`/assets/actions${
                currentTab == HomeTabs.Actions ? 'Blue' : ''
              }.svg`}
            ></Image>
            <div>Actions</div>
          </div>
          <div
            className={
              currentTab == HomeTabs.Summary
                ? styles.tabItemActiveSummary
                : styles.tabItem
            }
            onClick={() => dispatch(setCurrentTab(HomeTabs.Summary))}
          >
            <Image
              src={`/assets/summary${
                currentTab == HomeTabs.Summary ? 'Blue' : ''
              }.svg`}
            ></Image>
            <div>Summary</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeHeader;
