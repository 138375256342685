import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatMessage,
  ChatWordRequest,
  ChatWordResponse,
  ChatWordResponseChunk,
  isChatWordResponseChunk,
  MessageRole,
} from '../models/chat';
import { RootState } from './store';
import { v4 as uuidv4 } from 'uuid';
import { getDocumentContentAsText } from '../helper/office';
import { RequestEnum } from '../models/base';
import { sendMessage } from './socketSlice';

interface DocumentState {
  chatMessages: ChatMessage[];
  chatThreadId?: string;
  isStreaming: boolean;
  stopStreaming: boolean;
}

const initialState: DocumentState = {
  chatMessages: [],
  isStreaming: false,
  stopStreaming: false,
};

export const handleChatMessageResponse = createAsyncThunk(
  'qcounsel/handleChatMessageResponse',
  async (
    response: ChatWordResponse | ChatWordResponseChunk,
    { dispatch, getState }
  ) => {
    const { qcounsel } = getState() as RootState;
    const { chatMessages, isStreaming, stopStreaming } = qcounsel;
    if (stopStreaming) return;
    const isChunk = isChatWordResponseChunk(response);
    if (isChunk && !isStreaming) {
      dispatch(setIsStreaming(true));
    }
    if (!isChunk) {
      dispatch(setIsStreaming(false));
    }
    const newMessage: ChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk,
      attached: '',
      docs: response.docs,
    };

    const shouldReplaceLastMessage =
      chatMessages.length > 0 && chatMessages[chatMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatMessages.slice(0, -1), newMessage]
      : [...chatMessages, newMessage];

    dispatch(setChatMessages(updatedMessages));
    dispatch(setChatThreadId(response.thread_id));
  }
);

//---------------------------------------------------------------------------
export const removeChatMessage = createAsyncThunk(
  'qcounsel/removeChatMessage',
  async (messageId: String, { dispatch, getState }) => {
    console.log('remove last message with id', messageId);
    const state = getState() as RootState;
    const { chatMessages } = state.qcounsel;

    // Create a new array excluding the last element
    const updatedMessages = chatMessages.slice(0, -1);

    dispatch(setChatMessages(updatedMessages));
  }
);

export const sendChatMessage = createAsyncThunk(
  'qcounsel/sendChatMessage',
  async (
    {
      newMessage,
      add_current = true,
    }: { newMessage: ChatMessage; add_current: boolean },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { chatMessages } = state.qcounsel;
    const { chatThreadId } = state.qcounsel;

    const updatedMessages = [
      ...chatMessages,
      ...(add_current ? [newMessage] : []), // Conditionally add the current message
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
        attached: '',
      },
    ];

    dispatch(setChatMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ChatWordRequest = {
      action: RequestEnum.ChatWordRequest,
      thread_id: chatThreadId,
      messages: [...chatMessages, ...(add_current ? [newMessage] : [])],
      open_document_body: documentText,
    };

    console.log('Sending a new chat message:', newMessage);

    dispatch(sendMessage(chatMessageRequest));
  }
);
export const addAttachMessage = createAsyncThunk(
  'qcounsel/addAttachMessage',
  async (newMessage: ChatMessage, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { chatMessages } = state.qcounsel;
    const updatedMessages = [...chatMessages, newMessage];
    dispatch(setChatMessages(updatedMessages));
  }
);

export const qcounselSlice = createSlice({
  name: 'qcounsel',
  initialState,
  reducers: {
    setChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.chatMessages = action.payload;
    },
    setChatThreadId: (state, action: PayloadAction<string>) => {
      state.chatThreadId = action.payload;
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload;
    },
    setStopStreaming: (state, action: PayloadAction<boolean>) => {
      state.stopStreaming = action.payload;
    },
  },
});

//----------------------------------------------------------------------------------------
export const {
  setChatMessages,
  setChatThreadId,
  setIsStreaming,
  setStopStreaming,
} = qcounselSlice.actions;

export default qcounselSlice.reducer;
