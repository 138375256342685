import * as React from 'react';
import styles from './ReviewList.module.css';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react-components';
import { RootState } from '../../../../store/store';

export enum ReviewCategory {
  Unacceptable = 'Unacceptable',
  NonStandard = 'Non-Standard',
  Acceptable = 'Acceptable',
  Missing = 'Missing',
}
const steps = {
  initial: 1,
  chat: 2,
  review: 3,
  clauses: 4,
};

interface ReviewListItemProps {
  count: number;
  label: string;
  reviewCategory: ReviewCategory;
  setStepNo: React.Dispatch<React.SetStateAction<number>>;
  setReviewCategory: React.Dispatch<React.SetStateAction<ReviewCategory>>;
}
const ReviewListItem = (props: ReviewListItemProps) => {
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const cx = classNames.bind(styles);
  const className = cx({
    reviewItemContainer: true,
    unacceptable: props.reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: props.reviewCategory === ReviewCategory.NonStandard,
    acceptable: props.reviewCategory === ReviewCategory.Acceptable,
    missing: props.reviewCategory === ReviewCategory.Missing,
  });

  return (
    <div
      className={className}
      onClick={() => {
        if (props.count === 0) return;
        props.setReviewCategory(props.reviewCategory);
        props.setStepNo(steps.clauses);
      }}
    >
      <div className={styles.counter}>{props.count}</div>
      <p>{props.label}</p>
      <div className={styles.loading}>{isAnalyzing && <Spinner />}</div>
    </div>
  );
};

export interface ReviewListProps {
  setStepNo: React.Dispatch<React.SetStateAction<number>>;
  setReviewCategory: React.Dispatch<React.SetStateAction<ReviewCategory>>;
}
export const ReviewList: React.FC<ReviewListProps> = ({
  setStepNo,
  setReviewCategory,
}) => {
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  suggestions = suggestions.filter(x => x.user_suggestion_action !== 'deleted');
  console.log(suggestions, 'suggestions');
  return (
    <div className={styles.container}>
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Unacceptable').length}
        label="Unacceptable"
        reviewCategory={ReviewCategory.Unacceptable}
      />
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Non-Standard').length}
        label="Non-Standard"
        reviewCategory={ReviewCategory.NonStandard}
      />
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Acceptable').length}
        label="Acceptable"
        reviewCategory={ReviewCategory.Acceptable}
      />
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Missing').length}
        label="Missing"
        reviewCategory={ReviewCategory.Missing}
      />
    </div>
  );
};
