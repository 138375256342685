import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styles from './Clauses.module.css';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import diff from 'diff-match-patch';

import classNames from 'classnames/bind';
import {
  applySuggestion,
  scrollToSuggestion,
  setPreventAnalyzing,
} from '../../../../store/officeSlice';
import { OFFICE_ENCODING_COMMENT } from '../../../../helper/office';
import { Button, Textarea } from '@fluentui/react-components';
import {
  setchatSuggestMessages,
  setViewHeader,
} from '../../../../store/reviewSlice';
import { DocumentSuggestion } from '../../../../models/review';
import SuggestChat from './SuggestChat';
import { ReviewSteps } from './const';
import { ReviewCategory } from './ReviewList';

//refer to the headings for the clauses labels
const clauseActions = {
  dismiss: 'dismiss',
  keep: 'keep',
  insert: 'insert',
};
export const Clauses = ({
  reviewCategory,
  setStepNo,
  savedActions,
  setSavedActions,
  savedLinkedActions,
  setSavedLinkedActions,
}: {
  reviewCategory: ReviewCategory;
  setStepNo: any;
  savedActions: any;
  setSavedActions: any;
  savedLinkedActions: any;
  setSavedLinkedActions: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showPop, setShowPop] = useState(false);
  const [suggestAlt, setSuggestAlt] = useState(false);
  const [suggestAlt2, setSuggestAlt2] = useState<any>(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [newSuggest, setNewSuggest] = useState(undefined);
  const [linkNewSuggest, setLinkNewSuggest] = useState({}); //state for changes by link clause index
  const [clauseAction, setClauseAction] = useState(undefined);
  const [linkClauseAction, setLinkClauseAction] = useState({}); //state action by link clause index

  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  let chatSuggestMessages = useSelector(
    (state: RootState) => state.review.chatSuggestMessages
  );
  suggestions = suggestions.filter(x => x.user_suggestion_action !== 'deleted');
  const filteredSuggestions = suggestions.filter(
    x => x.category === reviewCategory
  );
  const [currentSuggestion, setCurrentSuggestion] =
    React.useState<DocumentSuggestion>(filteredSuggestions[0]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] =
    React.useState<number>(0);

  // @ts-ignore
  // const [setShowReviewButton] = useOutletContext();

  const cx = classNames.bind(styles);
  const className = cx({
    clauseText: true,
    unacceptable: reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: reviewCategory === ReviewCategory.NonStandard,
    acceptable: reviewCategory === ReviewCategory.Acceptable,
    missing: reviewCategory === ReviewCategory.Missing,
  });

  useEffect(() => {
    dispatch(setPreventAnalyzing(true));
    if (currentSuggestion) {
      dispatch(scrollToSuggestion(currentSuggestion));
    }
  }, [currentSuggestion]);

  useEffect(() => {
    //using the  original text find the heading of the clause from the document sections,

    if (currentSuggestion.original_text && currentSuggestion.suggested_text) {
      const dmp = new diff.diff_match_patch();
      const diffs = dmp.diff_main(
        currentSuggestion.original_text.replace(OFFICE_ENCODING_COMMENT, ''),
        currentSuggestion.suggested_text.replace(OFFICE_ENCODING_COMMENT, '')
      );
      dmp.diff_cleanupSemantic(diffs);
    }
    // added for demo hide replacement for missing clauses, this feature is not yet implemented
    if (savedActions[currentSuggestionIndex + reviewCategory]) {
      setClauseAction(savedActions[currentSuggestionIndex + reviewCategory]);
    }
    for (let linkIndex in Object.keys(currentSuggestion.related_updates)) {
      if (
        savedLinkedActions[currentSuggestionIndex + reviewCategory + linkIndex]
      ) {
        setLinkClauseAction(prev => {
          return {
            ...prev,
            [linkIndex]:
              savedLinkedActions[
                currentSuggestionIndex + reviewCategory + linkIndex
              ],
          };
        });
      }
    }
  }, [currentSuggestion]);

  const navigateToNextSuggestion = () => {
    if (currentSuggestionIndex < filteredSuggestions.length - 1) {
      const suggestion = filteredSuggestions[currentSuggestionIndex + 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  const navigateToPreviousSuggestion = () => {
    if (currentSuggestionIndex > 0) {
      const suggestion = filteredSuggestions[currentSuggestionIndex - 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };

  if (!currentSuggestion) {
    return null;
  }

  const clauseRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    dispatch(setViewHeader(false));
    return () => {
      dispatch(setViewHeader(true));
    };
  }, []);

  const cleanFlags = () => {
    setClauseAction(undefined);
    setNewSuggest(undefined);
    setLinkClauseAction({});
    setLinkNewSuggest({});
  };
  if (showPop)
    return (
      <SuggestChat
        onApply={() => {
          //update current suggestion based on alt1 (normal clause) or alt2 (link clause)
          // setnewsuggest to latest message in suggest
          let suggest = chatSuggestMessages[
            chatSuggestMessages.length - 1
          ].message.replace('Suggestion:', '');
          if (suggestAlt) {
            setNewSuggest(suggest);
          }
          if (suggestAlt2) {
            setLinkNewSuggest(prev => {
              return { ...prev, [suggestAlt2]: suggest };
            });
          }
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        onClose={() => {
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        current_clause={
          suggestAlt
            ? currentSuggestion.original_text
            : currentSuggestion.related_updates[suggestAlt2].original_text
        }
        ai_clause={
          suggestAlt
            ? currentSuggestion.suggested_text
            : currentSuggestion.related_updates[suggestAlt2].suggested_text
        }
      />
    );
  return (
    <div className={styles.clauseContainer}>
      <div className={styles.clauseHeader}>
        <div className={styles.clausetitle}>{`${
          currentSuggestionIndex + 1
        }.  ${reviewCategory} Clause ${currentSuggestion.clause_number}`}</div>
        <div className={styles.clauseValue}></div>
        <div className={styles.nav}>
          {currentSuggestionIndex > 0 && (
            <div
              ref={clauseRef}
              className={styles.clauseNav}
              onClick={() => {
                if (clauseAction) {
                  setSavedActions(prev => {
                    return {
                      ...prev,
                      [currentSuggestionIndex + reviewCategory]: clauseAction,
                    };
                  });
                }

                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToPreviousSuggestion();
              }}
            >
              {'<'}
            </div>
          )}

          <div className={styles.page}>{`${currentSuggestionIndex + 1} of ${
            filteredSuggestions.length
          }`}</div>
          {currentSuggestionIndex < filteredSuggestions.length - 1 && (
            <div
              className={styles.clauseNav}
              onClick={() => {
                setSavedActions(prev => {
                  return {
                    ...prev,
                    [currentSuggestionIndex + reviewCategory]: clauseAction,
                  };
                });
                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToNextSuggestion();
              }}
            >
              {'>'}
            </div>
          )}
        </div>
      </div>
      <div className={styles.commentaryHeading}>Commentary:</div>
      {(currentSuggestion.original_text ||
        reviewCategory == ReviewCategory.Missing) && (
        <div
          className={
            clauseAction === clauseActions.dismiss
              ? `${className} ${styles.disabledText}`
              : className
          }
          style={
            reviewCategory == ReviewCategory.Missing
              ? { minHeight: '160px' }
              : {}
          }
        >
          <div>{currentSuggestion.note}</div>
          {reviewCategory !== ReviewCategory.Missing && (
            <div>
              <div className={styles.linkTitle}>List of linked clauses:</div>
              {currentSuggestion.linked_clauses.map(c => {
                return (
                  <div className={styles.linkItem} key={c}>
                    {c}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}{' '}
      {![ReviewCategory.Missing].includes(reviewCategory) && (
        <button
          className={styles.rightButton}
          onClick={() => {
            //setDismiss(prev => !prev)
            if (clauseAction === clauseActions.dismiss) {
              setClauseAction(undefined);
              setLinkClauseAction({});
            } else {
              if (clauseAction === clauseActions.insert) {
                let suggest = { ...currentSuggestion };
                suggest.suggested_text = suggest.original_text;
                console.log(suggest);
                dispatch(applySuggestion(suggest));
              }

              setClauseAction(clauseActions.dismiss);
              setLinkClauseAction(prev => {
                let dismissObject = {};
                for (let index in Object.keys(
                  currentSuggestion.related_updates
                )) {
                  if (prev?.[index] == clauseActions.insert) {
                    let suggest = {
                      ...(currentSuggestion.related_updates[index] as any),
                    };
                    suggest.suggested_text = suggest.original_text;
                    dispatch(applySuggestion(suggest));
                  }
                  dismissObject[index] = clauseActions.dismiss;
                }
                return dismissObject;
              });
            }
          }}
        >
          {clauseAction == clauseActions.dismiss ? 'Edit' : 'Dismiss'}
        </button>
      )}
      <div className={styles.commentaryHeading}>
        Suggested Language for Clause {currentSuggestion.clause_number}
      </div>
      <Textarea
        className={styles.commentary}
        value={newSuggest || currentSuggestion.suggested_text}
        onChange={e => {
          setNewSuggest(e.target.value);
        }}
        onClick={() => {
          dispatch(scrollToSuggestion(currentSuggestion));
        }}
        disabled={clauseAction}
        style={
          reviewCategory == ReviewCategory.Missing ? { minHeight: '160px' } : {}
        }
      />
      <div className={styles.btnCnt}>
        <button
          className={
            [clauseActions.keep, clauseActions.insert].includes(clauseAction)
              ? styles.rightButton
              : styles.mainButton
          }
          disabled={clauseAction === clauseActions.dismiss}
          onClick={() => {
            if (clauseAction) {
              setClauseAction(undefined);
              //remove insert effect
              let suggest = { ...currentSuggestion };
              suggest.suggested_text = suggest.original_text;
              console.log(suggest);
              dispatch(applySuggestion(suggest));
            }
            if (!clauseAction) {
              setClauseAction(clauseActions.keep);
            }
          }}
        >
          {clauseAction
            ? 'Edit'
            : reviewCategory == ReviewCategory.Missing
            ? 'Ignore'
            : 'Keep Existing'}
        </button>
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={clauseAction === clauseActions.dismiss}
            onClick={() => {
              //think should set current clause and current linked clause or get it from index
              //go to chat pop
              setShowPop(true);
              setSuggestAlt(true);
              dispatch(setchatSuggestMessages([]));
            }}
          >
            Suggest Alternative
          </button>
        )}
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={clauseAction === clauseActions.dismiss}
            onClick={async () => {
              if (reviewCategory === ReviewCategory.Missing) {
                await navigator.clipboard.writeText(
                  newSuggest || currentSuggestion.suggested_text
                );
                return;
              }
              let suggest = { ...currentSuggestion };
              if (newSuggest) {
                suggest.suggested_text = newSuggest;
              }
              console.log('suggest', suggest);
              dispatch(applySuggestion(suggest));
              setClauseAction(clauseActions.insert);
            }}
          >
            {reviewCategory === ReviewCategory.Missing
              ? 'Copy'
              : 'Insert in Document'}
          </button>
        )}
      </div>
      {/* ------------------------------------------------------------------------------------------------------------- */}
      {reviewCategory != ReviewCategory.Missing && (
        <>
          {currentSuggestion.related_updates.map((linkClause, linkIndex) => {
            return (
              <>
                <div className={styles.commentaryHeading}>
                  Suggested Language for Linked Clause{' '}
                  {linkClause.clause_number}
                </div>
                <Textarea
                  className={styles.commentary}
                  value={
                    linkNewSuggest?.[linkIndex] || linkClause.suggested_text
                  }
                  disabled={linkClauseAction?.[linkIndex]}
                  onClick={() => {
                    console.log(currentSuggestion.related_updates[linkIndex]);
                    dispatch(
                      scrollToSuggestion(
                        currentSuggestion.related_updates[linkIndex]
                      )
                    );
                  }}
                  onChange={e => {
                    let value = e.target.value;
                    setLinkNewSuggest(prev => {
                      return {
                        ...prev,
                        [linkIndex]: value,
                      };
                    });
                  }}
                />
                <div className={styles.btnCnt}>
                  <button
                    className={
                      linkClauseAction?.[linkIndex] &&
                      [clauseActions.keep, clauseActions.insert].includes(
                        linkClauseAction[linkIndex]
                      )
                        ? styles.rightButton
                        : styles.mainButton
                    }
                    disabled={
                      linkClauseAction?.[linkIndex] === clauseActions.dismiss
                    }
                    onClick={() => {
                      if (linkClauseAction?.[linkIndex]) {
                        //remove insert effect
                        if (
                          linkClauseAction[linkIndex] === clauseActions.insert
                        ) {
                          let suggest = {
                            ...(currentSuggestion.related_updates[
                              linkIndex
                            ] as any),
                          };
                          suggest.suggested_text = suggest.original_text;
                          dispatch(applySuggestion(suggest));
                        }

                        setLinkClauseAction(prev => {
                          return {
                            ...prev,
                            [linkIndex]: undefined,
                          };
                        });
                      }
                      if (!linkClauseAction?.[linkIndex]) {
                        setLinkClauseAction(prev => {
                          return {
                            ...prev,
                            [linkIndex]: clauseActions.keep,
                          };
                        });
                      }
                    }}
                  >
                    {linkClauseAction?.[linkIndex] ? 'Edit' : 'Keep Existing'}
                  </button>
                  {![clauseActions.insert, clauseActions.keep].includes(
                    linkClauseAction?.[linkIndex]
                  ) && (
                    <button
                      className={styles.mainButton}
                      disabled={linkClauseAction?.[linkIndex]}
                      onClick={() => {
                        //think should set current clause and current linked clause or get it from index
                        //go to chat pop
                        setShowPop(true);
                        setSuggestAlt2(linkIndex);
                        setSuggestAlt(false);
                        dispatch(setchatSuggestMessages([]));
                      }}
                    >
                      Suggest Alternative
                    </button>
                  )}
                  {![clauseActions.insert, clauseActions.keep].includes(
                    linkClauseAction?.[linkIndex]
                  ) && (
                    <button
                      className={styles.mainButton}
                      disabled={
                        linkClauseAction?.[linkIndex] == clauseActions.dismiss
                      }
                      onClick={() => {
                        setLinkClauseAction(prev => {
                          return {
                            ...prev,
                            [linkIndex]: clauseActions.insert,
                          };
                        });
                        let suggest = {
                          ...(currentSuggestion.related_updates[
                            linkIndex
                          ] as any),
                        };
                        if (linkNewSuggest[linkIndex]) {
                          suggest.suggested_text = linkNewSuggest[linkIndex];
                        }
                        dispatch(applySuggestion(suggest));
                      }}
                    >
                      Insert in Document
                    </button>
                  )}
                </div>
              </>
            );
          })}
          {/* -------------------------------------------------------------------------- */}
          <div className={styles.commentaryHeading}>Legal Research</div>
          <div
            className={
              clauseAction === clauseActions.dismiss
                ? `${styles.commentary} ${styles.disabledText}`
                : styles.commentary
            }
          >
            <div> {currentSuggestion.legal_research.summary}</div>
            <div>
              {' '}
              {currentSuggestion.legal_research.sources.map(s => {
                return (
                  <div>
                    <a href={s.link} target="_blank" key={s.link}>
                      {s.title}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      <div
        className={styles.btnCnt}
        style={
          reviewCategory == ReviewCategory.Missing
            ? { marginTop: 'auto' }
            : { marginTop: '3px' }
        }
      >
        <button
          className={styles.acceptBtn}
          disabled={clauseAction === clauseActions.dismiss}
          onClick={() => {
            //insert all main clauses this handling for next clauses
            for (let index in filteredSuggestions) {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [index + reviewCategory]: clauseActions.insert,
                };
              });
              let suggest = { ...filteredSuggestions[index] };
              dispatch(applySuggestion(suggest));
              for (let linkIndex in filteredSuggestions[index]
                .related_updates) {
                setSavedLinkedActions(prev => {
                  return {
                    ...prev,
                    [index + reviewCategory + linkIndex]: clauseActions.insert,
                  };
                });
                dispatch(
                  applySuggestion(
                    filteredSuggestions[index].related_updates[linkIndex] as any
                  )
                );
              }
            }
            cleanFlags();
            setStepNo(ReviewSteps.review);
          }}
        >
          Accept all
        </button>
        <button
          className={styles.nextBtn}
          onClick={() => {
            //save clause action before leave
            let allKeys = Object.keys(currentSuggestion.related_updates);
            let linkClauseActionsValues = [];
            for (let key of allKeys) {
              let value = linkClauseAction[key] ?? undefined;
              linkClauseActionsValues.push(value);
            }
            let linkClauseActionsKeys = Object.keys(linkClauseAction);
            if (!clauseAction || linkClauseActionsValues.includes(undefined)) {
              setShowConfirmSave(true);
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]: clauseAction,
                };
              });
              setSavedLinkedActions(prev => {
                let result = { ...prev };
                for (let linkIndex of linkClauseActionsKeys) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction?.[linkIndex];
                }
                return result;
              });
              cleanFlags();
              navigateToNextSuggestion();
              if (currentSuggestionIndex == filteredSuggestions.length - 1) {
                //finish case all category clauses
                setStepNo(ReviewSteps.review);
              }
            }
          }}
        >
          {currentSuggestionIndex < filteredSuggestions.length - 1
            ? 'Next'
            : 'Finish'}
        </button>
      </div>
      {showConfirmSave &&
        ConfirmMessage(
          styles,
          () => {
            //on discard save action as it was dismissed
            setSavedActions(prev => {
              return {
                ...prev,
                [currentSuggestionIndex + reviewCategory]: undefined,
              };
            });
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              for (let linkIndex in linkClauseAction) {
                if (prev[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                }
              }
              return result;
            });
            cleanFlags();
            setShowConfirmSave(false);
            //go to next clause if no next go to review categories list
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          },
          () => {
            // on click Insert
            //save action was insert
            if (!clauseAction) {
              let suggest = { ...currentSuggestion };

              if (newSuggest) {
                suggest.suggested_text = newSuggest;
              }
              dispatch(applySuggestion(suggest));
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
            }

            //make insertion for any link clause have no action then save action as insert
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              let allKeys = Object.keys(currentSuggestion.related_updates);
              for (let linkIndex of allKeys) {
                if (linkClauseAction[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                } else {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    clauseActions.insert;
                  let suggest = {
                    ...currentSuggestion.related_updates[linkIndex],
                  };
                  if (linkNewSuggest[linkIndex]) {
                    suggest.suggested_text = linkNewSuggest[linkIndex];
                  }
                  dispatch(applySuggestion(suggest));
                }
              }
              return result;
            });
            //remove message
            setShowConfirmSave(false);
            cleanFlags();
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          }
        )}
    </div>
  );
};

const ConfirmMessage = (styles, onDiscard, onInsert) => {
  return (
    <div className={styles.confirmCnt}>
      <div className={styles.confirmMessage}>
        What should we do with the unsaved changes?
      </div>
      <div className={styles.confirmBtnsCnt}>
        <Button color="white" onClick={onDiscard}>
          Discard
        </Button>
        <Button color="#04142D" onClick={onInsert}>
          Insert
        </Button>
      </div>
    </div>
  );
};
