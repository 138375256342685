import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeStyles,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  shorthands,
  Image,
} from '@fluentui/react-components';

import { Layout } from './layout/Layout';

import { setCurrentTab } from '../store/officeSlice';
import { AppDispatch, RootState } from '../store/store';
import { HomeTabs } from './features/review/components/const';
import { summarizeDocument } from '../store/documentSlice';
import { getUserOfflineProcess } from '../store/activitySlice';
import { LoginPage } from 'qanoonisharedpackage';
import { tokenHelper } from '../helper/tokenHelper';
import HomeHeader from './shared/homeHeader/HomeHeader';
import { DraftPage } from './features/draft/DraftPage';
import { SendOutlook } from './features/sendOutlook/SendOutlook';
import ReviewTab from './features/review/ReviewTab';
import GlobalChange from './features/globalChanges/GlobalChange';
import HomeTab from './layout/HomePage';

export const HomePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const homeTabRef = React.useRef(null); // Create a ref for the Home tab
  const viewHeader = useSelector((state: RootState) => state.review.viewHeader);
  const currentTab = useSelector((state: RootState) => state.office.currentTab);
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const isLogin = useSelector((state: RootState) => state.socket.isLogin);
  const isEmptyDocument = useSelector(
    (state: RootState) => state.office.isEmptyDocument
  );
  const styles = useStyles();

  useEffect(() => {
    if (isEmptyDocument !== undefined && !isEmptyDocument && isConnected) {
      dispatch(summarizeDocument({}));
    }
  }, [isEmptyDocument, isConnected]);

  useEffect(() => {
    if (isConnected && isLogin) {
      dispatch(getUserOfflineProcess({}));
    }
  }, [isConnected, isLogin]);
  if (!isLogin)
    return (
      <div style={{ height: '100vh' }}>
        <LoginPage
          onClickBtn={() => {
            tokenHelper.onClickLogin();
          }}
          texts={[
            'Connect to your firm’s Data, through our Outlook Plugin and easy DMS integrations',
            'Use AI to Draft and Review Documents with your style and standards.',
            ' Use QCounsel as your legal search assistant across your firm’s information ',
          ]}
        />
      </div>
    );
  return (
    <Layout header={<HomeHeader />} footer={undefined}>
      {/*   <Spinner size="large" label="Getting document ready" /> */}
      {viewHeader && (
        <div className={styles.homeHeader}>
          <div
            className={
              currentTab == HomeTabs.Home
                ? `${styles.homeCntActive} ${styles.homeCnt}`
                : styles.homeCnt
            }
            onClick={() => dispatch(setCurrentTab(HomeTabs.Home))}
          >
            <Image
              src={
                currentTab == HomeTabs.Home
                  ? '/assets/home.png'
                  : '/assets/homeDark.png'
              }
              width={14}
              height={13}
            ></Image>
            <div> Home</div>
          </div>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton className={styles.menuButton}>
                {currentTab == HomeTabs.Home
                  ? 'Action'
                  : currentTab.length > 9
                  ? currentTab.toUpperCase().substring(0, 9) + '...'
                  : currentTab.toUpperCase()}
              </MenuButton>
            </MenuTrigger>

            <MenuPopover className={styles.menuPop}>
              <MenuList className={styles.menuList}>
                <MenuItem
                  className={styles.menuItem}
                  onClick={() => dispatch(setCurrentTab(HomeTabs.Draft))}
                >
                  Draft a New Document
                </MenuItem>
                <MenuItem
                  className={styles.menuItem}
                  onClick={() => dispatch(setCurrentTab(HomeTabs.Review))}
                >
                  Review a Document
                </MenuItem>
                <MenuItem className={styles.menuItem}>
                  Create Litigation Memo
                </MenuItem>

                <MenuItem
                  className={styles.menuItem}
                  onClick={() =>
                    dispatch(setCurrentTab(HomeTabs.Global_changes))
                  }
                >
                  Global Changes
                </MenuItem>
                <MenuItem
                  className={styles.menuItem}
                  onClick={() => dispatch(setCurrentTab(HomeTabs.sendOutlook))}
                >
                  Send to Outlook
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
      <div className={styles.tabContent}>
        {currentTab === HomeTabs.Home && <HomeTab />}
        {currentTab === HomeTabs.Review && (
          <ReviewTab
            onReturn={() => {
              if (homeTabRef.current) {
                homeTabRef.current.click(); // Trigger click on Home tab
              }
              dispatch(setCurrentTab(HomeTabs.Home));
            }}
          />
        )}
        {currentTab == HomeTabs.Draft && (
          <div>
            <DraftPage />
          </div>
        )}
        {currentTab == HomeTabs.Global_changes && <GlobalChange />}
        {currentTab == HomeTabs.sendOutlook && <SendOutlook />}
      </div>
    </Layout>
  );
};

const useStyles = makeStyles({
  menuList: {
    width: '112px !important',
    boxSizing: 'border-box',
    ...shorthands.border('0px'),
    ...shorthands.margin('0px'),
    minWidth: '112px !important',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1px'),
  },
  menuItem: {
    fontSize: '9px',
    ...shorthands.border('0px'),
    ...shorthands.margin('0px'),
    width: '100% !important',
    color: 'white',
    backgroundColor: '#2E2E2E',
    ...shorthands.borderRadius('0px'),

    '&:hover': {
      color: 'rgba(3, 135, 245, 1)',
      backgroundColor: '#2E2E2E',
    },
  },
  menuButton: {
    height: '26px',
    fontSize: '11px',
    color: 'rgba(59, 59, 59, 1)',
    justifyContent: 'space-between',
    width: '112px',
    backgroundColor: 'white',
  },
  menuPop: {
    width: '112px !important',
    boxSizing: 'border-box',
    ...shorthands.border('0px !important'),
    ...shorthands.margin('0px !important'),
    minWidth: '112px !important',
    backgroundColor: 'white',
  },
  homeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.padding('5px'),
    paddingBottom: '11px',
    ...shorthands.borderBottom('.6px', 'solid', 'rgba(183, 183, 183, 1)'),
    alignItems: 'center',
  },
  homeCnt: {
    display: 'flex',
    ...shorthands.gap('5px'),
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  homeCntActive: {
    color: 'rgba(3, 135, 245, 1)',
    ...shorthands.borderBottom('1px', 'solid', 'rgba(3, 135, 245, 1)'),
    paddingBottom: '3px',
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '9px',
    paddingBottom: '12px',
    backgroundColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)',
  },
  tabIcon: {
    color: '#3B3B3B',
  },
  selectedTabIcon: {
    color: '#0387f5',
  },
  selectedTabLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#0387f5',
  },
  tabLabel: {
    fontSize: '12px',
    color: '#3B3B3B',
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
});
