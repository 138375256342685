import { ResponseEnum, StatusEnum } from './base';

export interface SummarizeDocumentResponse {
  summary: string;
}
export interface SummarizeDocumentResponseChunk {
  summary: string;
}
export interface SummarizeDocumentRequest {
  document_text: string;
  action: string;
}
export interface DocumentReviewSummaryRequest {
  initial_document_state: string;
  action: string;
  current_document_state: string;
}

export interface DocumentReviewSummaryChatResponse {
  document_review_summary: string;
}
export interface DocumentReviewSummaryChatResponseChunk {
  document_review_summary: string;
}
export const isSummarizeDocumentResponse = (
  response: any
): response is SummarizeDocumentResponse => {
  return response.action === ResponseEnum.SummarizeDocumentResponse;
};
export const isSummarizeDocumentResponseChunk = (
  response: any
): response is SummarizeDocumentResponseChunk => {
  return response.action === ResponseEnum.SummarizeDocumentResponseChunk;
};
export const isDocumentReviewSummaryChatResponse = (
  response: any
): response is DocumentReviewSummaryChatResponse => {
  return response.action === ResponseEnum.DocumentReviewSummaryChatResponse;
};
export const isDocumentReviewSummaryChatResponseChunk = (
  response: any
): response is DocumentReviewSummaryChatResponseChunk => {
  return (
    response.action === ResponseEnum.DocumentReviewSummaryChatResponseChunk
  );
};
export type UpdateDocumentAttachmentResponse = {
  action: 'UpdateDocumentAttachmentResponse';
  status: StatusEnum;
  errorMessage?: string;
  success: boolean;
  documennt_attachment: DocumentAttachment;
};

export type DocumentAttachment = {
  id: string;
  name: string;
  path: string;
  type: string;
  sent: boolean;
};
