import * as React from 'react';
import styles from './ReviewProgress.module.css';
import { ProgressBar } from '@fluentui/react-components';
import {
  ThumbDislike16Regular,
  ThumbLike16Regular,
} from '@fluentui/react-icons';

export const ReviewProgress = ({
  value,
  max,
}: {
  value: number;
  max: number;
}) => {
  const progress = (value / max) * 100;
  return (
    <div className={styles.container}>
      <div className={styles.progressContainer}>
        <ProgressBar
          shape="rounded"
          className={styles.progressBar}
          thickness="large"
          value={value}
          max={max || 1} // Ensure max is never zero
        />
        <div className={styles.percentage}>
          {progress ? `${progress.toFixed(0)}%` : '0%'}
        </div>
        <div className={styles.likeCnt}>
          <ThumbLike16Regular />
          <ThumbDislike16Regular />
        </div>
      </div>
      <div className={styles.progressHeader}>Correction progress</div>
    </div>
  );
};
