import { BaseRequest, BaseResponse } from './base';

export interface SendToAnnotationQueueRequest extends BaseRequest {
  task_id: string;
  task_type: string;
}

export interface AnnotationTask extends BaseResponse {
  task_id: string;
  task_type: string;
  task_time: string;
}

export const isAnnotationTask = (response: any): response is AnnotationTask => {
  return response.action === 'AnnotationTask';
};
