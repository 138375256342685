import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DocumentReviewStarted,
  DocumentSuggestion,
  SectionSummaryResponseChunk,
} from '../models/review';
import {
  CreateTemplateResponse,
  DraftDocumentResponse,
  DraftDocumentResponseChunk,
  FindAndIterateSubsetQuestionsResponse,
  isCreateTemplateResponse,
  isDeleteTemplateResponse,
  isDraftDocumentResponse,
  isDraftDocumentResponseChunk,
  isFindAndIterateSubsetQuestionsResponse,
  isListContractsResponse,
  isListTemplatesResponse,
  isSectionSummaryResponseChunk,
  ListContractsResponse,
  ListTemplatesResponse,
  QuestionSubset,
} from '../models/draft';
import {
  isChatWordResponse,
  isChatWordResponseChunk,
  isReviewDocumentChatResponse,
  isReviewDocumentChatResponseChunk,
  isReviewDocumentSuggestAlternativeChatResponse,
} from '../models/chat';
import { isAnnotationTask } from '../models/annotation';
import { addAnnotation } from './annotationSlice';
import {
  addSuggestion,
  applyGlobalChanges,
  setIsAnalyzing,
  updateDocumentSectionSummary,
  setAttachmentSuccess,
  setDocumentId, // Add this
} from './officeSlice';
import {
  displayDraftChunk,
  displayDraftResponse,
  handleChatMessageResponse,
  handleCreateTemplateResponse,
  handleDeleteTemplateResponse,
  sendTemplateListRequest,
  setContracts,
  setIsLoadingListTemplate,
  setTemplateDocuments,
  setIsLoadingListContract,
  setMandatoryQuestionsSubset,
  setIsLoadingListMandatoryQuestionsSubset,
} from './draftSlice';
import { tokenHelper } from '../helper/tokenHelper'; // Import the TokenHelper
import {
  GetUserActivityResponse,
  GetUserProcessResponse,
  isGetUserActivityResonse,
  isGetUserOfflineProcessResponse,
  isSearchUserActivityResonse,
} from '../models/userActivity';
import {
  setLoadingSearch,
  setSearchUserActivities,
  setUserActivity,
  setUserOfflineProcess,
} from './activitySlice';
import {
  handleReviewChatMessageResponse,
  handleReviewChatSuggestAltMessageResponse,
} from './reviewSlice';

import {
  setDocument_Review_summary,
  setDocument_summary,
  setLoadReviewSummary,
} from './documentSlice';
import {
  isDocumentReviewSummaryChatResponse,
  isSummarizeDocumentResponse,
} from '../models/document';
import { RootState } from './store';
import { GlobalChangeWordResponse } from '../models/globalChanges';
interface SocketState {
  connection: WebSocket | null;
  isConnected: boolean;
  socketError?: string;
  isLogin?: boolean;
  token?: string;
  accountMgmt?: {
    account_email: string;
    account_response: string;
  };
}

const initialState: SocketState = {
  connection: null,
  isConnected: false,
  socketError: undefined,
  isLogin: true,
  token: undefined,
  accountMgmt: undefined,
};
let connection: WebSocket | undefined = undefined;

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const initSocket = createAsyncThunk(
  'socket/initSocket',
  async (_, { dispatch, getState }) => {
    console.log('Initializing socket');
    const state = getState() as RootState;
    const token = state.socket.token;

    if (connection) {
      connection = undefined;
    }
    if (!connection) {
      const socketUrlWithToken = `${SOCKET_URL}?token=${token}`;
      console.log('WebSocket URL:', socketUrlWithToken);
      connection = new WebSocket(socketUrlWithToken);
    }

    connection.onopen = () => {
      console.log('WebSocket connection opened.');
      dispatch(setConnected(true));

      // get templates for drafting
      dispatch(sendTemplateListRequest());
    };

    connection.onclose = () => {
      console.log('WebSocket connection closed.');
      dispatch(setConnected(false));
    };

    connection.onmessage = (event: MessageEvent<string>) => {
      const response: any = JSON.parse(event.data);
      console.log('message received', { response });
      if (response.status == 'error') {
        dispatch(setError(response.errorMessage));
      }

      //Sigin Response
      if (response.action === 'SignIn') {
        console.log('Sign In required');
        tokenHelper.getAccessToken(accesstoken => {
          dispatch(setTokenState(accesstoken));
        }, true);
        if (state.socket.isLogin) {
          dispatch(setIsLogin(false));
        }
      }

      switch (response.action) {
        case 'GetAccountMgmtResponse':
          console.log('Received isGetAccountMgmtResponse:', response);
          dispatch(setaccountMgmt(response));
          if (response && (response as any).account_response == 'SignOut') {
            console.log('SignOut');
            tokenHelper.getAccessToken(accesstoken => {
              dispatch(setTokenState(accesstoken));
            }, true);
          }
          break;
        case 'ListContractsResponse':
          const listContractsResponse = response as ListContractsResponse;
          console.log('ListContractsResponse', listContractsResponse.contracts);
          dispatch(setContracts(listContractsResponse.contracts));
          break;
        case 'FindAndIterateSubsetQuestionsResponse':
          const findAndIterateSubsetQuestionsResponse =
            response as FindAndIterateSubsetQuestionsResponse;
          console.log(
            'findAndIterateSubsetQuestionsResponse',
            findAndIterateSubsetQuestionsResponse
          );

          const question: QuestionSubset = {
            current_sequence:
              findAndIterateSubsetQuestionsResponse?.current_sequence,
            question: findAndIterateSubsetQuestionsResponse?.question,
            is_end: findAndIterateSubsetQuestionsResponse?.is_end,
          };

          console.log('question', question);
          dispatch(setMandatoryQuestionsSubset(question));
          dispatch(setIsLoadingListMandatoryQuestionsSubset(false));
          break;
        case 'OpenWordResponse':
          break;
        case 'DocumentReviewResponse':
          break;
        case 'DocumentSessionStarted':
          // to allow IDE to correctly infer the type of response
          const typedResponse = response as DocumentReviewStarted;
          console.log('DocumentReviewStarted', typedResponse.user_document_id);
          dispatch(setDocumentId(typedResponse.user_document_id));
          // startReview(response.user_document_id);
          dispatch(
            sendMessage({
              action: 'DocumentReviewRequestV2',
              user_document_id: typedResponse.user_document_id,
            })
          );
          break;
        case 'SuggestionItem':
          dispatch(addSuggestion(response as DocumentSuggestion));
          break;
        case 'DocumentReviewComplete':
          dispatch(setIsAnalyzing(false));
          break;
        case 'GlobalChangeWordResponse':
          dispatch(applyGlobalChanges(response as GlobalChangeWordResponse));
          break;
        case 'SectionSummaryResponse':
          console.log('SectionSummaryResponse', response);
          break;
        case 'UpdateDocumentAttachmentResponse':
          if (response.status === 'success') {
            dispatch(setAttachmentSuccess(true));
          }
          break;
        default:
          break;
      }

      // DRAFT LOGIC

      if (isDraftDocumentResponse(response)) {
        const draftDocumentResponse = response as DraftDocumentResponse;
        console.log('DocumentReviewResponse', draftDocumentResponse);
        dispatch(displayDraftResponse(draftDocumentResponse.document_text));
      } else if (isDraftDocumentResponseChunk(response)) {
        const draftDocumentResponseChunk =
          response as DraftDocumentResponseChunk;
        console.log('DocumentReviewResponseChunk', draftDocumentResponseChunk);
        dispatch(displayDraftChunk(draftDocumentResponseChunk.document_text));
      } else if (isListTemplatesResponse(response)) {
        const listTemplateResponse = response as ListTemplatesResponse;
        console.log(
          'ListTemplateLibraryResponse',
          listTemplateResponse.documents
        );
        dispatch(setTemplateDocuments(listTemplateResponse.documents));
        dispatch(setIsLoadingListTemplate(false));
      } else if (isCreateTemplateResponse(response)) {
        const createTemplateResponse = response as CreateTemplateResponse;
        console.log('CreateTemplateResponse', createTemplateResponse);
        dispatch(handleCreateTemplateResponse(createTemplateResponse));
      } else if (isSectionSummaryResponseChunk(response)) {
        console.log('SectionSummaryResponseChunk', response);
        dispatch(
          updateDocumentSectionSummary(response as SectionSummaryResponseChunk)
        );
      } else if (isDeleteTemplateResponse(response)) {
        console.log('DeleteTemplateResponse', response);
        dispatch(handleDeleteTemplateResponse(response));
      } else if (isListContractsResponse(response)) {
        const listContractsResponse = response as ListContractsResponse;
        console.log('ListContractsResponse', listContractsResponse.contracts);
        dispatch(setContracts(listContractsResponse.contracts));
        dispatch(setIsLoadingListContract(false));
      } else if (isFindAndIterateSubsetQuestionsResponse(response)) {
        const findAndIterateSubsetQuestionsResponse =
          response as FindAndIterateSubsetQuestionsResponse;
        console.log(
          'FindAndIterateSubsetQuestionsResponse',
          findAndIterateSubsetQuestionsResponse
        );
        const question: QuestionSubset = {
          current_sequence:
            findAndIterateSubsetQuestionsResponse?.current_sequence,
          question: findAndIterateSubsetQuestionsResponse?.question,
          is_end: findAndIterateSubsetQuestionsResponse?.is_end,
        };
        dispatch(setMandatoryQuestionsSubset(question));
        dispatch(setIsLoadingListMandatoryQuestionsSubset(false));
      }

      // CHAT LOGIC
      if (isChatWordResponseChunk(response) || isChatWordResponse(response)) {
        dispatch(handleChatMessageResponse(response));
      }
      if (
        isReviewDocumentChatResponseChunk(response) ||
        isReviewDocumentChatResponse(response)
      ) {
        dispatch(handleReviewChatMessageResponse(response));
      }
      if (
        // isReviewDocumentSuggestAlternativeChatResponseChunk(response) ||
        isReviewDocumentSuggestAlternativeChatResponse(response)
      ) {
        dispatch(handleReviewChatSuggestAltMessageResponse(response));
      }
      // DEV DEBUG LOGIC
      if (isAnnotationTask(response)) {
        console.log('AnnotationTask', response);
        dispatch(addAnnotation(response));
      }
      if (isGetUserActivityResonse(response)) {
        const userActivityResponse = response as GetUserActivityResponse;
        console.log('get user Activities response', response);
        dispatch(setUserActivity(userActivityResponse.activity));
      }
      if (isGetUserOfflineProcessResponse(response)) {
        const userOfflineResponse = response as GetUserProcessResponse;
        console.log('get user offline process response', response);
        dispatch(setUserOfflineProcess(userOfflineResponse.process));
      }
      if (isSummarizeDocumentResponse(response)) {
        dispatch(setDocument_summary(response.summary));
      }
      if (isDocumentReviewSummaryChatResponse(response)) {
        dispatch(setDocument_Review_summary(response.document_review_summary));
        dispatch(setLoadReviewSummary(false));
      }
      if (isSearchUserActivityResonse(response)) {
        const searchUserActivityResponse = response as GetUserActivityResponse;
        console.log('get user search Activities response', response);
        dispatch(setSearchUserActivities(searchUserActivityResponse.activity));
        if (searchUserActivityResponse.activity.length == 0) {
          dispatch(setLoadingSearch('no-result'));
        } else {
          dispatch(setLoadingSearch(false));
        }
      }
    };
  }
);

export const sendMessage = createAsyncThunk<void, any>(
  'socket/sendMessage',
  async (message: any, { dispatch }) => {
    console.log('sending message', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Socket is open. Sending message.');
      dispatch(setError(undefined));
      connection.send(JSON.stringify(message));
    }
  }
);

export const sendFeedback = createAsyncThunk<void, any>(
  'socket/sendFeedback',
  async (message: any, { dispatch }) => {
    console.log('sending feedback', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Socket is open. Sending feedback.');
      dispatch(setError(undefined));
      connection.send(JSON.stringify(message));
    }
  }
);
export const GetAccountMgmt = createAsyncThunk<void, any>(
  'socket/GetAccountMgmt',
  async (message: any, {}) => {
    console.log('sending feedback', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Sending to GetAccountMgmtRequest:', message);
      connection.send(JSON.stringify(message));
    }
  }
);

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      console.log('setting error', action.payload);
      state.socketError = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setTokenState: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setaccountMgmt: (state, action: PayloadAction<any>) => {
      state.accountMgmt = action.payload;
    },
  },
});

export const {
  setConnected,
  setError,
  setIsLogin,
  setTokenState,
  setaccountMgmt,
} = socketSlice.actions;

export default socketSlice.reducer;
