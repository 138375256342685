import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SendOutlook.module.css';
//import { Home16Regular, Info12Regular } from '@fluentui/react-icons';
import {
  Button,
  Image,
  Toast,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components';
import { GLOBAL_TOAST_ID } from '../../layout/Layout';
import { RootState } from '../../../store/store';
import { MarkdownRenderer } from '../../shared/misc/MarkdownRenderer';

export const EmailSummaryOutlook = ({}: //  setShowSummary,
{
  // setShowSummary: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [summ, setSumm] = useState('');
  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);

  //const [isLoading, setIsLoading] = useState(false);
  const [dis, setDis] = useState(true);

  const review_summary = useSelector(
    (state: RootState) => state.document.review_summary
  );

  useEffect(() => {
    if (review_summary) {
      setSumm(review_summary);
    }
  }, [review_summary]);

  return (
    <div className={styles.summaryCnt}>
      {/* <div className={styles.summaryHeader}>
        <div onClick={() => {}}>
          <SmallLeftArrowIcon />
        </div>
        <div onClick={() => dispatch(setCurrentTab(HomeTabs.Home))}>
          <Home16Regular />
        </div>
      </div> */}
      <div className={styles.summaryTitle}>Email summary</div>
      {!dis && (
        <textarea
          className={styles.summaryTextarea}
          value={summ}
          onChange={e => setSumm(e.target.value)}
          disabled={dis}
          placeholder="write here ..."
        />
      )}
      {dis && (
        <div className={styles.summaryarea}>
          <MarkdownRenderer markdown={summ} />
        </div>
      )}
      <div className={styles.editIcon} onClick={() => setDis(prev => !prev)}>
        {dis ? (
          <Image src="/assets/edit.png" />
        ) : (
          <Image src="/assets/edit.png" color="#0387F5" />
        )}
      </div>
      <div className={styles.btn2Cnt}>
        <Button
          className={styles.button2}
          // onClick={() => setShowSummary(true)}
          //onClick={_ => handleSendAttachment()}
          //disabled={isLoading}
        >
          <div
            className={styles.titleContainer}
            onClick={async () => {
              await navigator.clipboard.writeText(summ);
              dispatchToast(
                <Toast>
                  <ToastTitle>Email Copied</ToastTitle>
                </Toast>,
                { position: 'bottom-end', intent: 'success' }
              );
            }}
          >
            <Image src="/assets/outlook.png" className={styles.titleIcon} />
            <div>Copy Email</div>

            {/* <GreenTickIcon className={styles.successIcon} /> */}
          </div>
        </Button>
        {/*  <Info12Regular color="#919191" className={styles.infoIcon} /> */}
      </div>
    </div>
  );
};
