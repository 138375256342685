import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../ReviewTab.module.css';
import {
  ThumbDislike16Regular,
  ThumbLike16Regular,
} from '@fluentui/react-icons';
import { MarkdownRenderer } from '../../../shared/misc/MarkdownRenderer';
import { RootState } from '../../../../store/store';
import { Spinner } from '@fluentui/react-components';
const DocumentSummary = () => {
  const summary = useSelector(
    (state: RootState) => state.document.document_summary
  );
  if (!summary) {
    return <Spinner size="small" style={{ padding: '25px' }} />;
  }
  return (
    <div>
      <div className={styles.likeCnt}>
        <ThumbLike16Regular />
        <ThumbDislike16Regular />
      </div>{' '}
      <div className={styles.summaryPage}>
        <MarkdownRenderer markdown={summary} />
      </div>
    </div>
  );
};

export default DocumentSummary;
