//---- Constants -----------------

export const OFFICE_ENCODING_COMMENT = '\u0005';

//--------------------------------------
export async function replaceDocumentContent(
  newContent: string
): Promise<void> {
  try {
    await Word.run(async function (context) {
      console.log(`Replacing document content with: ${newContent}`);
      const body = context.document.body;
      body.clear();

      if (isValidHtml(newContent)) {
        console.log('Content is valid HTML');
        body.insertHtml(newContent, 'End');
      } else {
        console.log('Inserting text instead of HTML');
        body.insertText(newContent, 'End');
      }

      await context.sync().then(function () {
        console.log('Content replaced successfully.');
      });

      console.log('New content added to the document body.');
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  } catch (error) {
    console.error(`Failed to insert text: ${error}`);
  }
}

export async function addDocumentContent(newContent: string): Promise<void> {
  try {
    await Word.run(async function (context) {
      console.log(`Adding new content to the document: ${newContent}`);
      const body = context.document.body;

      if (isValidHtml(newContent)) {
        console.log('Content is valid HTML');
        body.insertHtml(newContent, 'End');
      } else {
        console.log('Inserting text instead of HTML');
        body.insertText(newContent, 'End');
      }

      await context.sync().then(function () {
        console.log('Content added successfully.');
      });

      console.log('New content added to the document body.');
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  } catch (error) {
    console.error(`Failed to add text: ${error}`);
  }
}

export async function getDocumentContentAsHtml(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;
    const bodyHtml = body.getHtml();

    await context.sync();

    console.log('Retrieved document content as HTML:', bodyHtml.value);
    return bodyHtml.value;
  }).catch(function (error) {
    console.error('Error retrieving document as HTML:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as HTML: ${error}`);
  });
}
//----------------------------------------------------------------------------
export async function getDocumentContentAsText(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;

    // Load the 'text' property of the 'body' object
    body.load('text');

    await context.sync();

    const text = body.text;

    return text;
  }).catch(function (error) {
    console.error('Error retrieving document as Text:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as Text: ${error}`);
  });
}
//---------------------------------------------------------------------------------------------------
function isValidHtml(htmlString: string): boolean {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const parserErrors = doc.querySelectorAll('parsererror');

  return parserErrors.length === 0;
}
//----------------------------------------------------------------------------------------------------------------------------

export async function getDocumentContentAsTextV2(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;

    // Retrieve document content as HTML
    const htmlContent = body.getHtml();
    await context.sync();

    // Convert HTML to plain text
    const plainText = convertHtmlToPlainText(htmlContent.value);

    console.log(
      'Retrieved document content as plain text from HTML:',
      plainText
    );
    return plainText;
  }).catch(function (error) {
    console.error(
      'Error retrieving document content as plain text from HTML:',
      JSON.stringify(error)
    );
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(
      `Failed to retrieve document content as plain text from HTML: ${error}`
    );
  });
}
//------------------------------------------------------------------------------------
// Helper function to convert HTML to plain text
function convertHtmlToPlainText(htmlContent: string): string {
  // Replace <br> and <p> tags with newlines to keep the structure
  let textContent = htmlContent.replace(/<br\s*\/?>/gi, '\n');
  textContent = textContent.replace(/<\/p>/gi, '\n');

  // Remove all other HTML tags, keeping only the visible text
  textContent = textContent.replace(/<[^>]+>/g, '');

  // Decode HTML entities, like &nbsp; and &amp;
  textContent = textContent.replace(/&nbsp;/gi, ' ');
  textContent = textContent.replace(/&amp;/gi, '&');
  textContent = textContent.replace(/&lt;/gi, '<');
  textContent = textContent.replace(/&gt;/gi, '>');
  textContent = textContent.replace(/\n{3,}/g, '\n\n');

  // Trim any extra whitespace
  return textContent.trim();
}
