import React, { useEffect, useState } from 'react';
import styles from './ReviewTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewCategory, ReviewList } from './components/ReviewList';
import Chat from './components/Chat';
import IntialPage from './components/IntialPage';
import { ReviewSteps } from './components/const';
import { Clauses } from './components/Clauses';
import { AppDispatch, RootState } from '../../../store/store';
import Header from './components/Header';
import { setReviewStepNumber } from '../../../store/reviewSlice';

const ReviewTab = ({ onReturn }: any) => {
  // Scroll to the bottom whenever a new message is added
  const [reviewCategory, setReviewCategory] =
    useState<ReviewCategory>(undefined);
  const [savedActions, setSavedActions] = useState({});
  const [savedLinkedActions, setSavedLinkedActions] = useState({}); //[currentIndex+category+link index]
  const [typingDelay, settypingDelay] = useState(25);
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  let setpNo = useSelector((state: RootState) => state.review.reviewStepNumber);
  const dispatch = useDispatch<AppDispatch>();

  const filteredSuggestions = suggestions.filter(
    x => x.category === reviewCategory
  );
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const setStepNo = (stepNo: number) => {
    dispatch(setReviewStepNumber(stepNo));
  };

  return (
    <div
      className={styles.reviewCnt}
      style={{ marginTop: setpNo == ReviewSteps.clauses ? undefined : '15px' }}
    >
      <Header
        setpNo={setpNo}
        settypingDelay={settypingDelay}
        setStepNo={setStepNo}
        max={filteredSuggestions.length}
        value={
          Object.keys(savedActions).filter(
            key =>
              key.includes(reviewCategory) && savedActions?.[key] != undefined
          ).length
        }
      />
      {setpNo == ReviewSteps.initial && <IntialPage setStepNo={setStepNo} />}
      {(setpNo == ReviewSteps.chat || setpNo === undefined) && (
        <Chat
          setStepNo={setStepNo}
          onReturn={onReturn}
          typingDelay={typingDelay}
          settypingDelay={settypingDelay}
        />
      )}
      {setpNo == ReviewSteps.review && (
        <div className={styles.reviewCnt}>
          <>
            <div className={styles.reviewCntText} style={{ marginTop: '40px' }}>
              Review {isAnalyzing ? 'in progress' : 'completed.'}
            </div>
            <ReviewList
              setStepNo={setStepNo}
              setReviewCategory={setReviewCategory}
            />
          </>
        </div>
      )}
      {setpNo == ReviewSteps.clauses && (
        <Clauses
          reviewCategory={reviewCategory}
          setStepNo={setStepNo}
          savedActions={savedActions}
          setSavedActions={setSavedActions}
          savedLinkedActions={savedLinkedActions}
          setSavedLinkedActions={setSavedLinkedActions}
          suggestions={suggestions}
        />
      )}
    </div>
  );
};

export default ReviewTab;
