import { BaseRequest, BaseResponse, ResponseEnum } from './base';
import { SectionSummaryResponseChunk } from './review';
import { DocumentAttachment } from './attachment';
import { ChatMessage } from './chat';

export interface DraftDocumentRequest extends BaseRequest {
  template_id?: string;
  thread_id?: string;
  chat_history: ChatMessage[];
}

export interface DraftDocumentResponse extends BaseResponse {
  response: string;
  document_text: string;
}

export interface DraftDocumentResponseChunk extends BaseResponse {
  response: string;
  document_text: string;
}
// TEMPLATE LIBRARY

export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
  Unknown = 'Unknown',
}

export interface TemplateDocument {
  id?: string;
  name: string;
  type: DocumentType;
  text: string;
  path: string;
}

export interface ListTemplatesRequest extends BaseRequest {}

//  Mandatory Questions
export type QuestionSubset = {
  current_sequence: number;
  question: string;
  is_end: boolean;
};

// CONTRACTS
export type Contract = {
  id: number;
  contract_type: string;
  representing_parties: string;
};

export interface ListContractsResponse extends BaseResponse {
  contracts: Contract[];
}

export interface ListContractsRequest extends BaseRequest {}
export interface FindAndIterateSubsetQuestionsRequest extends BaseRequest {
  question_id: number;
  current_sequence: number;
  earlier_user_response: string;
}

export interface FindAndIterateSubsetQuestionsResponse extends BaseResponse {
  current_sequence: number;
  question: string;
  is_end: boolean;
}

// TEMPLATE LIBRARY

export interface ListTemplatesResponse extends BaseResponse {
  documents: TemplateDocument[];
}

// CREATE TEMPLATE

export interface CreateTemplateResponse extends BaseResponse {
  document_id: string;
}

export interface DeleteTemplateResponse extends BaseResponse {
  template_id: string;
}

export interface CreateTemplateRequest extends BaseRequest {
  document: TemplateDocument;
}

export interface DeleteTemplateRequest extends BaseRequest {
  template_id: string;
}

export interface UpdateDocumentAttachmentRequest extends BaseRequest {
  document_attachment: DocumentAttachment;
}

// HELPER

export const isDraftDocumentResponse = (
  response: any
): response is DraftDocumentResponse => {
  return response.action === ResponseEnum.DraftDocumentResponse;
};

export const isDraftDocumentResponseChunk = (
  response: any
): response is DraftDocumentResponseChunk => {
  return response.action === ResponseEnum.DraftDocumentResponseChunk;
};

export const isListTemplatesResponse = (
  response: any
): response is ListTemplatesResponse => {
  return response.action === ResponseEnum.ListTemplatesResponse;
};

export const isCreateTemplateResponse = (
  response: any
): response is CreateTemplateResponse => {
  return response.action === ResponseEnum.CreateTemplateResponse;
};

export const isDeleteTemplateResponse = (
  response: any
): response is DeleteTemplateResponse => {
  return response.action === ResponseEnum.DeleteTemplateResponse;
};

export const isListContractsResponse = (
  response: any
): response is ListContractsResponse => {
  return response.action === ResponseEnum.ListContractsResponse;
};

export const isFindAndIterateSubsetQuestionsResponse = (
  response: any
): response is FindAndIterateSubsetQuestionsResponse => {
  return response.action === ResponseEnum.FindAndIterateSubsetQuestionsResponse;
};

export const isSectionSummaryResponseChunk = (
  response: any
): response is SectionSummaryResponseChunk => {
  return response.action === ResponseEnum.SectionSummaryResponseChunk;
};
