import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendMessage } from './socketSlice';
import { RequestEnum } from '../models/base';
import { getDocumentContentAsText } from '../helper/office';
import {
  DocumentReviewSummaryRequest,
  SummarizeDocumentRequest,
} from '../models/document';

interface DocumentState {
  document_summary: string;
  load_sumaary?: boolean | 'streaming';
  review_summary: string;
  loadReviewSummary: boolean | 'streaming';
  initial_document_state: string;
}

const initialState: DocumentState = {
  document_summary: undefined,
  review_summary: undefined,
  loadReviewSummary: false,
  initial_document_state: '',
  load_sumaary: false,
};
//-------------------------------------------------------------
export const summarizeDocument = createAsyncThunk(
  '/document/getSummary',
  async ({}: {}, { dispatch }) => {
    const documentText = await getDocumentContentAsText();
    dispatch(setLoadSummary(true));
    dispatch(setInitial_document_state(documentText));
    const requestTosend: SummarizeDocumentRequest = {
      action: RequestEnum.SummarizeDocumentRequest,
      document_text: documentText,
    };
    //save first version of document content
    console.log('get document summary');
    dispatch(sendMessage(requestTosend));
  }
);
//------------------------------------------------------------------
export const summarizeReviewDocument = createAsyncThunk(
  '/document/getReviewSummary',
  async (
    { initial_document_state }: { initial_document_state: string },
    { dispatch }
  ) => {
    const current_content = await getDocumentContentAsText();
    if (current_content === initial_document_state) {
      dispatch(setDocument_Review_summary('No changes Detected'));
      dispatch(setLoadReviewSummary(false));
      return;
    } else {
      dispatch(setLoadReviewSummary(true));
      const requestTosend: DocumentReviewSummaryRequest = {
        action: RequestEnum.DocumentReviewSummaryRequest,
        initial_document_state: initial_document_state,
        current_document_state: current_content,
      };

      console.log('get document Email summary');

      dispatch(sendMessage(requestTosend));
    }
  }
);
//---------------------------------------------------------------------------
export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocument_summary: (state, action: PayloadAction<string>) => {
      state.document_summary = action.payload;
    },
    setDocument_Review_summary: (state, action: PayloadAction<string>) => {
      state.review_summary = action.payload;
    },
    setInitial_document_state: (state, action: PayloadAction<string>) => {
      state.initial_document_state = action.payload;
    },
    setLoadReviewSummary: (
      state,
      action: PayloadAction<boolean | 'streaming'>
    ) => {
      state.loadReviewSummary = action.payload;
    },
    setLoadSummary: (state, action: PayloadAction<boolean | 'streaming'>) => {
      state.load_sumaary = action.payload;
    },
  },
});

export const {
  setInitial_document_state,
  setDocument_summary,
  setDocument_Review_summary,
  setLoadReviewSummary,
  setLoadSummary,
} = documentSlice.actions;

export default documentSlice.reducer;
