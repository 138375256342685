import { tokenHelper } from './tokenHelper';
import axios from 'axios';
export function extractMatterName(matter_summart: string) {
  const startMarker = '### Instructions';
  const endMarker = '### Summary of Events';

  // Find the position of the markers
  const startIndex = matter_summart.indexOf(startMarker) + startMarker.length;
  const endIndex = matter_summart.indexOf(endMarker);

  // Check if both markers exist
  if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
    return null; // Return null if markers are not found or out of order
  }

  // Extract the text between the markers
  const extractedText = matter_summart.substring(startIndex, endIndex).trim();
  return extractedText;
}
//--------------------------------------------------------------
export function formatUserActivityDate(dateString: string): string {
  const date: Date = new Date(dateString);
  const now: Date = new Date();

  // Ensure both date and now are valid Date objects
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  const diffTime: number = now.getTime() - date.getTime(); // use .getTime() to get timestamps as numbers
  const diffHours: number = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays: number = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  if (diffDays === 0) {
    // Today case
    if (diffHours > 0) {
      return `${diffHours} hours ago`;
    } else {
      const diffMinutes: number = Math.floor(diffTime / (1000 * 60));
      return `${diffMinutes} minutes ago`;
    }
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else {
    const month: string = months[date.getMonth()];
    const day: string = date.getDate().toString().padStart(2, '0');
    return `${month} ${day}`;
  }
}
export const getAzureBlobSaSToken = (blobPath: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/blob-sas?blob_path=${blobPath}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sas = response.data.sas;

        resolve(sas);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};
//---------------------------------------------------------------------------
export const openWord = async (documentUrl: string, isMacos: boolean) => {
  console.log(documentUrl, isMacos);
  const containerPath = documentUrl.replace(
    `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
    ''
  );
  const sas = await getAzureBlobSaSToken(containerPath);
  const docUrlWithSas = `${documentUrl}?${sas}`;
  if (isMacos) {
    window.open(docUrlWithSas);
  } else {
    window.open(`ms-word:ofe|u|${docUrlWithSas}`);
  }
};

export const openPDF = async (documentUrl: string) => {
  console.log(documentUrl);
  const containerPath = documentUrl.replace(
    `https://${process.env.QANOONI_DOCS_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/documentattachments/`,
    ''
  );
  const sas = await getAzureBlobSaSToken(containerPath);
  const docUrlWithSas = `${documentUrl}?${sas}`;
  window.open(docUrlWithSas, '_blank');
};
//--------------------------------------------------------------
export enum ActivityType {
  docRevew = 'document-review',
  docDraft = 'document-draft',
  matterReview = 'matter-review',
  docProposal = 'document-proposal',
  docMemo = 'document-memo',
}
export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
}
