import React, { useEffect, useRef } from 'react';
import { MessageRole, ReviewChatMessage } from '../../../../models/chat';
import {
  sendChatMessage,
  setChatMessages,
} from '../../../../store/reviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { v4 as uuidv4 } from 'uuid';
import {
  clearSuggestions,
  setIsAnalyzing,
  setPreventAnalyzing,
} from '../../../../store/officeSlice';
import BotChating from '../../../shared/botChating/BotChating';
import { sendMessage } from '../../../../store/socketSlice';
import { ReviewSteps } from './const';
import styles from '../ReviewTab.module.css';
const Chat = ({ onReturn, setStepNo, typingDelay, settypingDelay }: any) => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();
  const chatMessages: ReviewChatMessage[] = useSelector(
    (state: RootState) => state.review.chatMessages
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const documentId = useSelector((state: RootState) => state.office.documentId);
  let chatingLoading = useSelector(
    (state: RootState) => state.review.chatingLoading
  );
  const onMessageSend = () => {
    if (typingDelay === 0) {
      settypingDelay(25);
    }
    if (isConnected && value !== '') {
      dispatch(
        sendChatMessage({
          id: uuidv4(),
          message: value,
          role: MessageRole.User,
          isChunk: false,
        })
      );
      setValue('');
    }
  };
  useEffect(() => {
    if (isConnected && chatMessages.length == 0) {
      dispatch(
        sendChatMessage({
          id: uuidv4(),
          message: 'Hi',
          role: MessageRole.User,
          isChunk: false,
        })
      );
    }
    // setdelay(40);
  }, [isConnected, chatMessages]);

  const reviewDocument = () => {
    dispatch(setPreventAnalyzing(false));
    dispatch(setIsAnalyzing(true));
    dispatch(clearSuggestions());
    dispatch(
      sendMessage({
        action: 'DocumentReviewRequestV2',
        user_document_id: documentId,
      })
    );
    setStepNo(ReviewSteps.review);
  };

  return (
    <div>
      <div className={styles.reviewTitle}>Review</div>
      <BotChating
        typingDelay={typingDelay}
        height="62vh"
        loading={chatingLoading}
        onYes={() => {
          reviewDocument();
          //dispatch(setChatMessages([]));
        }}
        onNo={() => {
          dispatch(setChatMessages([]));
          onReturn();
        }}
        onMessageSend={onMessageSend}
        chatMessages={chatMessages.slice(1).map(c => {
          return { ...c, is_yes_no: c.is_finished };
        })}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default Chat;
