import React from 'react';
import { Button } from '@fluentui/react-components';

export const ConfirmMessage = (styles, onDiscard, onInsert) => {
  return (
    <div className={styles.confirmCnt}>
      <div className={styles.confirmMessage}>
        What should we do with the unsaved changes?
      </div>
      <div className={styles.confirmBtnsCnt}>
        <Button color="white" onClick={onDiscard}>
          Discard
        </Button>
        <Button color="#04142D" onClick={onInsert}>
          Insert
        </Button>
      </div>
    </div>
  );
};
