import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

interface TypingMarkdownRendererProps {
  markdown: string;
  typingDelay?: number; // Typing typingDelay in milliseconds per character
  animate?: boolean; // Enable or disable the animation
  onComplete?: () => void; // Callback when typing animation finishes
}

export const TypingMarkdownRenderer: React.FC<TypingMarkdownRendererProps> = ({
  markdown,
  typingDelay = 25,
  animate = false,
  onComplete,
}) => {
  const [currentText, setCurrentText] = useState<string>(
    animate ? '' : markdown
  );
  const indexRef = useRef<number>(0); // Store index value for typing
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Store the interval ID for cleanup

  useEffect(() => {
    // If animation is disabled, show the full text immediately
    if (!animate || typingDelay == 0) {
      setCurrentText(markdown);
      if (onComplete) {
        onComplete();
      }
      return undefined;
    }

    // Prevent animation if markdown is empty
    if (!markdown) return undefined;

    // Reset the index when new markdown is passed
    indexRef.current = 0;

    // Start typing animation
    intervalRef.current = setInterval(() => {
      setCurrentText(prevText => {
        const nextChar = markdown[indexRef.current];
        indexRef.current++; // Increment the index for the next character
        return prevText + nextChar; // Append the next character
      });

      // Stop the animation once all characters are added
      if (indexRef.current >= markdown.length - 1) {
        clearInterval(intervalRef.current!);
        if (onComplete) {
          onComplete(); // Call the onComplete callback when finished
        }
      }
    }, typingDelay);

    // Cleanup interval on component unmount or when dependencies change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [markdown, animate]); // Ensure `markdown` and `animate` are included in dependencies

  return <ReactMarkdown skipHtml={false}>{currentText}</ReactMarkdown>;
};
