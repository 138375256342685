import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { sendMessage } from './socketSlice';
import { RootState } from './store';
import { RequestEnum } from '../models/base';
import {
  MessageRole,
  ReviewChatMessage,
  ReviewDocumentChatResponse,
  ReviewDocumentChatRequest,
  isReviewDocumentChatResponseChunk,
  SuggestChatMessage,
  ReviewDocumentSuggestChatRequest,
  SuggestAlternativeChatResponse,
} from '../models/chat';

import { getDocumentContentAsText } from '../helper/office';

interface ReviewChatState {
  chatMessages: ReviewChatMessage[];
  chatThreadId?: string;
  chatThreadId2?: string;
  viewHeader?: boolean;
  chatSuggestMessages: SuggestChatMessage[];
  chatingLoading?: boolean;
}

const initialState: ReviewChatState = {
  chatMessages: [],
  viewHeader: true,
  chatSuggestMessages: [],
  chatingLoading: false,
};

export const sendChatMessage = createAsyncThunk(
  'review/sendContextMessage',
  async (newMessage: ReviewChatMessage, { dispatch, getState }) => {
    dispatch(setchatingLoading(true));
    const state = getState() as RootState;
    const { chatMessages } = state.review;
    const { chatThreadId } = state.review;

    const updatedMessages = [
      ...chatMessages,
      newMessage,
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
      },
    ];

    dispatch(setChatMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ReviewDocumentChatRequest = {
      action: RequestEnum.ReviewDocumentChatRequest,
      thread_id: chatThreadId,
      messages: [...chatMessages, newMessage],
      document_text: documentText,
    };
    console.log('Sending a new chat message:', newMessage);
    dispatch(sendMessage(chatMessageRequest));
  }
);

export const handleReviewChatMessageResponse = createAsyncThunk(
  'review/handleReviewChatMessageResponse',
  async (response: ReviewDocumentChatResponse, { dispatch, getState }) => {
    const { review } = getState() as RootState;
    const { chatMessages } = review;
    const isChunk = isReviewDocumentChatResponseChunk(response);
    const newMessage: ReviewChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk,
      is_finished: response.is_finished,
    };

    const shouldReplaceLastMessage =
      chatMessages.length > 0 && chatMessages[chatMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatMessages.slice(0, -1), newMessage]
      : [...chatMessages, newMessage];

    dispatch(setChatMessages(updatedMessages));
    dispatch(setChatThreadId(response.thread_id));
    dispatch(setchatingLoading(false));
  }
);
//---------------------------------------------------------------------------
export const sendSuggestChatMessage = createAsyncThunk(
  'review/sendSuggestAltContextMessage',
  async (
    {
      newMessage,
      clause,
      ai_clause,
    }: {
      newMessage: SuggestChatMessage;
      clause: string;
      ai_clause: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { chatSuggestMessages } = state.review;
    const { chatThreadId2 } = state.review;

    const updatedMessages = [
      ...chatSuggestMessages,
      newMessage,
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
      },
    ];

    dispatch(setchatSuggestMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ReviewDocumentSuggestChatRequest = {
      action: RequestEnum.SuggestAlternativeChatRequest,
      thread_id: chatThreadId2,
      messages: [...chatSuggestMessages, newMessage],
      document_text: documentText,
      current_clause: clause,
      ai_suggested_clause: ai_clause,
    };
    console.log('Sending a new chat message:', newMessage);
    dispatch(sendMessage(chatMessageRequest));
    dispatch(setchatingLoading(true));
  }
);
/////////////////-------------------------------------------
export const handleReviewChatSuggestAltMessageResponse = createAsyncThunk(
  'review/handleReviewChatSuggestAlternativeMessageResponse',
  async (response: SuggestAlternativeChatResponse, { dispatch, getState }) => {
    const { review } = getState() as RootState;
    const { chatSuggestMessages } = review;
    //const isChunk = isReviewDocumentChatResponseChunk(response);
    const newMessage: SuggestChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk: false,
      is_question: response.is_question,
      is_suggestion_message: response.is_suggestion_message,
    };

    /*     const shouldReplaceLastMessage =
      chatSuggestMessages.length > 0 &&
      chatSuggestMessages[chatSuggestMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatSuggestMessages.slice(0, -1), newMessage]
      : [...chatSuggestMessages, newMessage];

 */
    let updatedMessages = [...chatSuggestMessages.slice(0, -1), newMessage];
    dispatch(setchatSuggestMessages(updatedMessages));
    dispatch(setChatThreadId2(response.thread_id));
    dispatch(setchatingLoading(false));
  }
);
export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    // CHAT
    setChatMessages: (state, action: PayloadAction<ReviewChatMessage[]>) => {
      state.chatMessages = action.payload;
    },
    setchatSuggestMessages: (
      state,
      action: PayloadAction<SuggestChatMessage[]>
    ) => {
      state.chatSuggestMessages = action.payload;
    },
    setChatThreadId: (state, action: PayloadAction<string>) => {
      state.chatThreadId = action.payload;
    },
    setChatThreadId2: (state, action: PayloadAction<string>) => {
      state.chatThreadId2 = action.payload;
    },
    setViewHeader: (state, action: PayloadAction<boolean>) => {
      state.viewHeader = action.payload;
    },
    setchatingLoading: (state, action: PayloadAction<boolean>) => {
      state.chatingLoading = action.payload;
    },
  },
});

export const {
  // chat
  setChatMessages,
  setChatThreadId,
  setChatThreadId2,
  setViewHeader,
  setchatSuggestMessages,
  setchatingLoading,
} = reviewSlice.actions;

export default reviewSlice.reducer;
