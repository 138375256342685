import React from 'react';
import ReactMarkdown from 'react-markdown';

interface MarkdownRendererProps {
  markdown: string;
  className?: string;
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdown,
  className,
}) => {
  return (
    <ReactMarkdown className={className} skipHtml={false}>
      {markdown}
    </ReactMarkdown>
  );
};
