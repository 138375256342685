import * as React from 'react';
//import styles from './SendOutlook.module.css';
//import { Badge, Button, Divider } from '@fluentui/react-components';
import { useEffect } from 'react';
//import { MailAddRegular, MailAttachRegular } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setViewHeader } from '../../../store/reviewSlice';
import { EmailSummaryOutlook } from './EmailSummary';
import {
  setLoadReviewSummary,
  summarizeReviewDocument,
} from '../../../store/documentSlice';
import { Spinner } from '@fluentui/react-components';
import { AppDispatch, RootState } from '../../../store/store';
export const SendOutlook = () => {
  const dispatch = useDispatch<AppDispatch>();
  //const [showSummary, setShowSummary] = useState(false);
  const initial_document_state = useSelector(
    (state: RootState) => state.document.initial_document_state
  );
  const loadReviewSummary = useSelector(
    (state: RootState) => state.document.loadReviewSummary
  );
  console.log(initial_document_state);
  useEffect(() => {
    dispatch(setViewHeader(true));

    dispatch(
      summarizeReviewDocument({
        initial_document_state: initial_document_state,
      })
    );
  }, []);
  if (loadReviewSummary) {
    return <Spinner size="small" style={{ padding: '30px' }} />;
  }
  return <EmailSummaryOutlook /* setShowSummary={setShowSummary} */ />;
  /*  return (
    <div className={styles.container}>
      <div className={styles.emailContainer}>
        <MailAttachRegular className={styles.emailIcon} />
        <div className={styles.emailText}>Email </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button className={styles.button} onClick={() => setShowSummary(true)}>
          <div className={styles.titleContainer}>
            <MailAddRegular className={styles.titleIcon} />
            <div>Send to Outlook</div>
          
          </div>
        </Button>
        <Divider className={styles.divider}>What happens next?</Divider>
        <div className={styles.furtherInstructionsContainer}>
          <Badge className={styles.badge}>1</Badge>
          <div className={styles.furtherInstructionsText}>Open Outlook</div>
          <Badge className={styles.badge}>2</Badge>
          <div className={styles.furtherInstructionsText}>
            Open email and activate <strong>Qanooni</strong> plugin use this
            document as an attachment
          </div>
        </div>
      </div>
    </div>
  ); */
};
