import React from 'react';
import styles from '../ReviewTab.module.css';
import { ReviewSteps, ReviewTabs } from './const';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../../../../store/officeSlice';
import { HomeTabs } from './const';
import { SmallLeftArrowIcon } from '../../../../assets/icons/SmallLeftArrow';
import { Image } from '@fluentui/react-components';
import { RootState } from '../../../../store/store';
import { ReviewProgress } from './ReviewProgress';

const Header = ({ setpNo, tab, setTab, setStepNo, value, max }) => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state: RootState) => state.office.currentTab);

  return (
    <>
      {[ReviewSteps.clauses].includes(setpNo) && tab === ReviewTabs.review && (
        <div
          className={styles.backIcon}
          onClick={() => {
            if (setpNo === ReviewSteps.clauses) {
              setStepNo(ReviewSteps.review);
              setTab(ReviewTabs.review);
            } else {
              // setStepNo(ReviewSteps.chat);
              setTab(ReviewTabs.review);
            }
          }}
        >
          <SmallLeftArrowIcon />
        </div>
      )}

      <div
        className={
          [ReviewSteps.clauses, ReviewSteps.summary].includes(setpNo)
            ? `${styles.tabsActive} ${styles.tabs}`
            : styles.tabs
        }
      >
        <div
          className={
            tab == ReviewTabs.review
              ? `${styles.tab} ${styles.tabActive}`
              : styles.tab
          }
          onClick={() => {
            setTab(ReviewTabs.review);
            //setStepNo(ReviewSteps.chat);
          }}
        >
          Review
        </div>
        <div
          className={
            tab == ReviewTabs.summary
              ? `${styles.tab} ${styles.tabActive}`
              : styles.tab
          }
          onClick={() => {
            setTab(ReviewTabs.summary);
            // setStepNo(ReviewSteps.summary);
          }}
        >
          Document Summary
        </div>
        {/* here will remove summary from the header changes  */}
        {[ReviewSteps.clauses].includes(setpNo) &&
          tab === ReviewTabs.review && (
            <div
              className={styles.homeIconReview}
              onClick={() => dispatch(setCurrentTab(HomeTabs.Home))}
            >
              <Image
                src={
                  currentTab == HomeTabs.Home
                    ? '/assets/home.png'
                    : '/assets/homeDark.png'
                }
                width={14}
                height={13}
              ></Image>
            </div>
          )}
      </div>

      {setpNo == ReviewSteps.clauses && tab === ReviewTabs.review && (
        <div className={styles.progressCnt}>
          <ReviewProgress value={value} max={max} />
        </div>
      )}
    </>
  );
};

export default Header;
