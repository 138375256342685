import { Button } from '@fluentui/react-components';
import { ArrowRotateCounterclockwiseRegular } from '@fluentui/react-icons';
import React from 'react';
import styles from '../ReviewTab.module.css';
const steps = {
  initial: 1,
  chat: 2,
  review: 3,
};

const IntialPage = ({ setStepNo }: any) => {
  return (
    <div className={styles.btnCnt}>
      <Button
        //disabled={isAnalyzing || !isConnected} // Disable if WebSocket is not connected
        icon={<ArrowRotateCounterclockwiseRegular color="white" />}
        //onClick={reviewDocument}
        className={styles.reviewBtn}
        onClick={() => setStepNo(steps.chat)}
      >
        Review Document
      </Button>
      <div>Clause by Clause Analysis</div>
    </div>
  );
};

export default IntialPage;
