export enum StatusEnum {
  Success = 'success',
  Error = 'error',
  Completed = 'Completed',
}

export enum ResponseEnum {
  OpenWordResponse = 'OpenWordResponse',
  DocumentSessionStarted = 'DocumentSessionStarted',
  DocumentReviewResponse = 'DocumentReviewResponse',
  SuggestionResponse = 'Suggestion',
  DraftDocumentResponse = 'DraftDocumentResponse',
  DraftDocumentResponseChunk = 'DraftDocumentResponseChunk',
  ListTemplatesResponse = 'ListTemplatesResponse',
  CreateTemplateResponse = 'CreateTemplateResponse',
  SectionSummaryResponseChunk = 'SectionSummaryResponseChunk',
  ChatWordResponse = 'ChatWordResponse',
  ChatWordResponseChunk = 'ChatWordResponseChunk',
  UpdateDocumentAttachmentResponse = 'UpdateDocumentAttachmentResponse',
  AnnotationTaskResponse = 'AnnotationTask',
  DeleteTemplateResponse = 'DeleteTemplateResponse',
  GetUserActivityResponse = 'GetUserActivityResponse',
  SearchUserActivityResponse = 'SearchUserActivityResponse',
  ReviewDocumentChatResponse = 'ReviewDocumentChatResponse',
  ReviewDocumentChatResponseChunk = 'ReviewDocumentChatResponseChunk',
  ListContractsResponse = 'ListContractsResponse',
  FindAndIterateSubsetQuestionsResponse = 'FindAndIterateSubsetQuestionsResponse',
  SummarizeDocumentResponse = 'SummarizeDocumentResponse',
  SuggestAlternativeChatResponse = 'SuggestAlternativeChatResponse',
  SuggestAlternativeChatResponseChunk = 'SuggestAlternativeChatResponseChunk',
  DocumentReviewSummaryChatResponse = 'DocumentReviewSummaryChatResponse',
  GetUserProcessResponse = 'GetUserProcessResponse',
}

export enum RequestEnum {
  UserFeedbackRequest = 'UserFeedbackRequest',
  GetUserProcessResponse = 'GetUserProcessResponse',
  FeedbackGetRequest = 'FeedbackGetRequest',
  GetAccountMgmtRequest = 'GetAccountMgmtRequest',
  OpenWordRequest = 'OpenWordRequest',
  DocumentReviewRequest = 'DocumentReviewRequest',
  DraftDocumentRequest = 'DraftDocumentRequestV2',
  ListTemplatesRequest = 'ListTemplatesRequest',
  CreateTemplateRequest = 'CreateTemplateRequest',
  UpdateDocumentAttachmentRequest = 'UpdateDocumentAttachmentRequest',
  ChatWordRequest = 'ChatWordRequest',
  SendToAnnotationQueueRequest = 'SendToAnnotationQueueRequest',
  DeleteTemplateRequest = 'DeleteTemplateRequest',
  GetUserActivityRequest = 'GetUserActivityRequest',
  SearchUserActivityRequest = 'SearchUserActivityRequest',
  ReviewDocumentChatRequest = 'ReviewDocumentChatRequest',
  ListContractsRequest = 'ListContractsRequest',
  FindAndIterateSubsetQuestionsRequest = 'FindAndIterateSubsetQuestionsRequest',
  SummarizeDocumentRequest = 'SummarizeDocumentRequest',
  SuggestAlternativeChatRequest = 'SuggestAlternativeChatRequest',
  DocumentReviewSummaryRequest = 'DocumentReviewSummaryRequest',
  GetUserProcessRequest = 'GetUserProcessRequest',
}

export interface BaseResponse {
  action: ResponseEnum;
  status: StatusEnum;
  errorMessage?: string | null;
}

export interface BaseRequest {
  action: RequestEnum;
}
