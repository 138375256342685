// anything to with office, when reading the entire text or listening to changes it wll
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DocumentSuggestion,
  RelatedUpdate,
  SectionSummaryResponseChunk,
} from '../models/review';
import { sendMessage } from './socketSlice';
import {
  generateNGrams,
  getMatchingParagraph,
  IParagraph,
  NGRAM_LENGTH,
} from '../helper/textMining';
import { RootState } from './store';

import diff from 'diff-match-patch';

import {
  getDocumentContentAsTextV2,
  OFFICE_ENCODING_COMMENT,
} from '../helper/office';
import {
  closeAsync,
  getFileAsync,
  getSliceAsync,
  loadFileName,
} from '../helper/file';
import { RequestEnum } from '../models/base';
import { UpdateDocumentAttachmentRequest } from '../models/draft';
import axios from 'axios';
import { tokenHelper } from '../helper/tokenHelper';
import { HomeTabs } from '../taskpane/features/review/components/const';
import {
  GlobalChangeProcessedResults,
  GlobalChangeWordResponse,
} from '../models/globalChanges';
enum DiffType {
  Equal = 0,
  Insert = 1,
  Delete = -1,
}

type DocumentSection = {
  heading: string;
  paragraphs: IParagraph[];
  isWaitingForCommentary: boolean;
  summary: string;
};

/* global Office Word */

interface OfficeState {
  documentId: string;
  documentText: string;
  sanitizedParagraphs: IParagraph[];
  suggestions: DocumentSuggestion[];
  isAnalyzing: boolean;
  preventAnalyzing: boolean;
  isProcessingGlobalChanges: boolean;
  globalChanges: GlobalChangeWordResponse | undefined;
  globalChangesProcessedResults: GlobalChangeProcessedResults | undefined;
  documentSections: DocumentSection[];
  attachmentSuccess: boolean;
  isEmptyDocument?: boolean; //
  currentTab?: string;
  actionsTab?: string;
  globalChatMessages: { role: string; message: string }[];
}

const initialState: OfficeState = {
  documentId: '',
  documentText: '',
  suggestions: [],
  isAnalyzing: true,
  preventAnalyzing: false,
  sanitizedParagraphs: [],
  isProcessingGlobalChanges: false,
  globalChanges: undefined,
  documentSections: [],
  globalChangesProcessedResults: undefined,
  attachmentSuccess: false,
  isEmptyDocument: undefined,
  currentTab: HomeTabs.Home,
  actionsTab: undefined,

  globalChatMessages: [
    {
      message:
        'Make changes to the document, such as making a bulk replacement, changing a specific section, improving the language, or adding background information etc.',
      role: 'ai',
    },
  ],
};

const Colours = {
  Unacceptable: 'red',
  'Non-Standard': 'yellow',
  Acceptable: 'green',
  Missing: 'gray',
};

enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
}

const getUser = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        console.log('Acquired token:', token);
        const response = await axios.get(
          `${process.env.BACKEND_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('User data:', response.data);
        resolve(response.data);
      } catch (error) {
        console.error('Error getting user:', error);
        reject(error);
      }
    });
  });
};

const getAzureBlobClient = (): Promise<BlobServiceClient> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/sas-token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const url = response.data.url;
        console.log(url);
        resolve(new BlobServiceClient(url));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};
// no longer required to colour the text
export const highlightSuggestion = createAsyncThunk(
  'office/highlightSuggestion',
  async (documentSuggestion: DocumentSuggestion, { dispatch, getState }) => {
    dispatch(addSuggestion(documentSuggestion));

    const state: RootState = getState() as RootState;
    const matchingParagraph = getMatchingParagraph(
      documentSuggestion.original_text,
      state.office.sanitizedParagraphs
    );

    if (!matchingParagraph) {
      console.log(
        'No matching paragraph found for suggestion',
        documentSuggestion
      );
      return;
    }

    // highlight the suggestion in the document
    await Word.run(async context => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load('text');

      await context.sync();
      const range = paragraphs.items[matchingParagraph.index].getRange();
      range.font.highlightColor = Colours[documentSuggestion.category];
      await context.sync();
    });
  }
);

export const scrollToSuggestion = createAsyncThunk(
  'office/scrollToSuggestion',
  async (
    documentSuggestion: DocumentSuggestion | RelatedUpdate,
    { getState }
  ) => {
    const state: RootState = getState() as RootState;
    let paragraphs: IParagraph[] = [];
    if (state.office.sanitizedParagraphs.length == 0) {
      await Word.run(async context => {
        const wordParagraphs = context.document.body.paragraphs;
        wordParagraphs.load('text');
        await context.sync();

        for (let i = 0; i < wordParagraphs.items.length; i++) {
          const text = wordParagraphs.items[i].text;
          paragraphs.push({
            text,
            index: i,
            nGrams: generateNGrams(text, NGRAM_LENGTH),
          }); // Generate N-grams for each paragraph for matching later
        }
        setSanitizedParagraphs(paragraphs);
      });
    } else {
      paragraphs = state.office.sanitizedParagraphs;
    }
    const matchingParagraph = getMatchingParagraph(
      documentSuggestion.original_text,
      paragraphs
    );

    if (!matchingParagraph) {
      console.log(
        'No matching paragraph found for suggestion',
        documentSuggestion
      );
      return;
    }

    // highlight the suggestion in the document
    await Word.run(async context => {
      const paragraphs = context.document.body.paragraphs;
      paragraphs.load('text');

      await context.sync();
      const range = paragraphs.items[matchingParagraph.index].getRange();
      range.select();
      await context.sync();
    });
  }
);

export const scrollToParagraph = createAsyncThunk(
  'office/scrollToParagraph',
  async (paragraph: string, { getState }) => {
    const state: RootState = getState() as RootState;

    try {
      const matchingParagraph = getMatchingParagraph(
        paragraph,
        state.office.sanitizedParagraphs
      );

      if (!matchingParagraph) {
        return;
      }

      await Word.run(async context => {
        const paragraphs = context.document.body.paragraphs;
        paragraphs.load('text');

        await context.sync();
        const range = paragraphs.items[matchingParagraph.index].getRange();
        range.select();
        await context.sync();
      });
    } catch (e) {
      console.error(e);
    }
  }
);

export const reapplySuggestions = createAsyncThunk(
  'office/highlightSuggestion',
  async (_, { dispatch, getState }) => {
    const state: RootState = getState() as RootState;

    dispatch(clearHighlights());

    console.log('suggestions', state.office.suggestions);
    for (const suggestion of state.office.suggestions) {
      console.log(
        'Reapplying suggestion',
        suggestion.original_text,
        suggestion.category
      );
      if (suggestion.user_suggestion_action === 'deleted') continue;
      try {
        const matchingParagraph = getMatchingParagraph(
          suggestion.original_text,
          state.office.sanitizedParagraphs
        );

        if (!matchingParagraph) {
          continue;
        }

        await Word.run(async context => {
          const paragraphs = context.document.body.paragraphs;
          paragraphs.load('text');

          await context.sync();
          const range = paragraphs.items[matchingParagraph.index].getRange();
          range.font.highlightColor = Colours[suggestion.category];
          await context.sync();
        });
      } catch (error) {
        console.log('Error reapplying suggestion', error);
      }
    }
  }
);
export const clearHighlights = createAsyncThunk(
  'office/clearHighlights',
  async () => {
    await clearHighlightsInDocument();
  }
);

const clearHighlightsInDocument = async () => {
  await Word.run(async context => {
    // Get the body of the document
    const body = context.document.body;

    // Load the body with the highlighted text
    body.load('paragraphs/items/font/highlightColor');
    await context.sync();

    for (let i = 0; i < body.paragraphs.items.length; i++) {
      const paragraph = body.paragraphs.items[i];
      paragraph.font.highlightColor = null; // Clear highlight color
    }
    await context.sync();
  });
};

export const applySuggestion = createAsyncThunk(
  'office/applySuggestion',
  async (suggestion: DocumentSuggestion | any, { getState }) => {
    const state: RootState = getState() as RootState;
    try {
      await applyDifferencesToWordDocument(
        suggestion.original_text,
        suggestion.suggested_text,
        state.office.sanitizedParagraphs
      );
    } catch (error) {
      try {
        await applyDifferencesToWordDocument(
          suggestion.suggested_text,
          suggestion.original_text,
          state.office.sanitizedParagraphs
        );
      } catch (error) {
        console.log('Error reapplying suggestion', error);
      }
    }
  }
);
export const clearAndApplySuggest = createAsyncThunk(
  'office/applySuggestion',
  async (suggestion: DocumentSuggestion | any, { getState }) => {
    const state: RootState = getState() as RootState;

    try {
      await clearAndApplyDifferencesToWordDocument(
        suggestion.original_text,
        suggestion.suggested_text,
        state.office.sanitizedParagraphs
      );
    } catch (error) {
      console.log('Error reapplying suggestion', error);
    }
  }
);
export const applyGlobalChanges = createAsyncThunk(
  'office/applySuggestion',
  async (globalChanges: GlobalChangeWordResponse, { dispatch, getState }) => {
    const state: RootState = getState() as RootState;

    //save the global changes
    dispatch(setGlobalChanges(globalChanges));

    const globalChangeProcessedResults: GlobalChangeProcessedResults = {
      processedCount: 0,
      totalToProcess: globalChanges.replacements.length,
      errors: [],
    };

    try {
      for (const replacement of globalChanges.replacements) {
        try {
          await applyDifferencesToWordDocument(
            replacement.original_clause,
            replacement.replacement_clause,
            state.office.sanitizedParagraphs
          );
          globalChangeProcessedResults.processedCount++;
        } catch (error) {
          const errorMessage = `Error applying global change ${replacement.global_change_id}: ${error}`;
          globalChangeProcessedResults.errors.push(errorMessage);
          console.log(errorMessage);
        }
      }
    } catch (e) {
      console.log('Error applying global changes', e);
      // dispatch toast error
    } finally {
      dispatch(setGlobalChangesProcessedResults(globalChangeProcessedResults));
      dispatch(setIsProcessingGlobalChanges(false));
    }
  }
);
const clearAndApplyDifferencesToWordDocument = async (
  originalClause: string,
  replacementClause: string,
  sanitizedParagraphs: IParagraph[]
) => {
  console.log('here');

  const matchingParagraph = getMatchingParagraph(
    originalClause,
    sanitizedParagraphs
  );

  if (!matchingParagraph) {
    console.log('No matching paragraph found for suggestion', originalClause);
    throw new Error('No matching paragraph found for suggestion');
  }

  console.log('matchingParagraph', matchingParagraph);
  console.log('originalClause', originalClause);
  console.log('sanitized Paragraphs', { sanitizedParagraphs });

  await Word.run(async context => {
    const paragraphs = context.document.body.paragraphs;
    paragraphs.load('text');
    await context.sync();

    // Find the matching paragraph
    const paragraph = paragraphs.items[matchingParagraph.index];
    paragraph.load('text'); // Load the current text of the paragraph
    await context.sync();

    console.log('Existing paragraph:', paragraph.text);

    // Clear the old paragraph text
    const rangeToClear = paragraph.getRange();
    rangeToClear.clear(); // This will remove all the text in the paragraph
    await context.sync();

    // Insert the replacement text into the now empty paragraph
    paragraph.insertText(replacementClause, Word.InsertLocation.replace);
    await context.sync();

    console.log('Updated paragraph:', replacementClause);
  });
};

const applyDifferencesToWordDocument = async (
  originalClause: string,
  replacementClause: string,
  sanitizedParagraphs: IParagraph[]
) => {
  //console.log('Starting applyDifferencesToWordDocument...');

  const SEARCH_LENGTH = 200;

  const matchingParagraph = getMatchingParagraph(
    originalClause,
    sanitizedParagraphs
  );

  if (!matchingParagraph) {
    const matchingParagraph = getMatchingParagraph(
      replacementClause,
      sanitizedParagraphs
    );
    if (!matchingParagraph) {
      console.error(
        'No matching paragraph found for suggestion:',
        originalClause
      );
      throw new Error('No matching paragraph found for suggestion');
    }
  }

  //console.log('Matching Paragraph:', matchingParagraph);

  const dmp = new diff.diff_match_patch();
  const diffs = dmp.diff_main(
    originalClause.replace(OFFICE_ENCODING_COMMENT, ''),
    replacementClause.replace(OFFICE_ENCODING_COMMENT, ''),
    false
  );
  dmp.diff_cleanupSemantic(diffs);

  await Word.run(async context => {
    const paragraphs = context.document.body.paragraphs;
    paragraphs.load('text');

    await context.sync();
    //console.log('Total Paragraphs:', paragraphs.items.length);

    if (matchingParagraph.index >= paragraphs.items.length) {
      console.error('Matching paragraph index is out of bounds');
      throw new Error('Paragraph index is out of bounds');
    }

    const paragraph = paragraphs.items[matchingParagraph.index];
    paragraph.load('text');
    await context.sync();

    if (!paragraph.text) {
      console.error('Paragraph not loaded or text missing');
      throw new Error('Paragraph not loaded or text missing');
    }

    console.log('Matching Paragraph Text:', paragraph.text);

    let currentRangeStart = paragraph.getRange('Start');
    let currentRangeEnd = paragraph.getRange('End');
    currentRangeStart.load(); // Explicitly load the range
    await context.sync();
    // Validate that the range is valid
    if (!currentRangeStart || currentRangeStart.isNullObject) {
      console.error('Invalid currentRangeStart. Reinitializing...');
      currentRangeStart = paragraph.getRange('Start');
      currentRangeStart.load(); // Explicitly load the range
      await context.sync(); // Sync to ensure the range is ready
    }

    for (const diff of diffs) {
      const [diffType, value] = diff;
      //console.log(`Processing diff: ${diffType}, value: "${value}"`);

      switch (diffType) {
        case DiffType.Equal:
          if (value.length > SEARCH_LENGTH) {
            // Break large text into batches for searching
            const textBatches = splitWordsIntoBatches(value, 220);
            for (const str of textBatches) {
              currentRangeStart = await skipToRange(
                currentRangeStart,
                currentRangeEnd,
                str,
                context
              );
            }
          } else {
            currentRangeStart = await skipToRange(
              currentRangeStart,
              currentRangeEnd,
              value,
              context
            );
            currentRangeStart.load(); // Load the range
            await context.sync(); // Sync context
          }
          break;

        case DiffType.Delete:
          if (value.length > SEARCH_LENGTH) {
            const textBatches = splitWordsIntoBatches(value, 220);
            for (const str of textBatches) {
              currentRangeStart = await deleteTextInParagraph(
                currentRangeStart,
                currentRangeEnd,
                str,
                context
              );
            }
          } else {
            currentRangeStart = await deleteTextInParagraph(
              currentRangeStart,
              currentRangeEnd,
              value,
              context
            );
          }
          break;

        case DiffType.Insert:
          if (!currentRangeStart || currentRangeStart.isNullObject) {
            console.error(
              'currentRangeStart is undefined or invalid before insert, reloading paragraph...'
            );
            currentRangeStart = paragraph.getRange('End'); // Reinitialize range to the end of the paragraph.
            currentRangeStart.load(); // Explicitly load the range
            await context.sync(); // Sync to ensure the range is ready
          }

          if (currentRangeStart && !currentRangeStart.isNullObject) {
            currentRangeStart.insertText(value, 'End');
          } else {
            console.error(
              `Cannot insert text. Invalid range. Value to insert: "${value}"`
            );
            throw new Error(
              `Cannot insert text. Invalid range. Value to insert: "${value}"`
            );
          }
          break;

        default:
          console.warn(`Unknown diff type: ${diffType}`);
      } /* catch (error) {
        console.error(
          `Error processing diff (type: ${diffType}, value: "${value}"):`,
          error
        );
      } */
    }

    await context.sync();
  });
};
const deleteTextInParagraph = async (
  paragraph: Word.Range,
  paragraphEnd: Word.Range,
  value: string,
  context: Word.RequestContext
): Promise<Word.Range> => {
  let range: Word.Range;

  const newSearchRange = paragraph.getRange('After').expandTo(paragraphEnd);
  await context.sync();
  const searchResult = newSearchRange.search(value, {});
  searchResult.load('text');
  await context.sync();
  if (searchResult.items.length > 0) {
    range = searchResult.items[0].getRange();
    range.clear();
    await context.sync();
  }
  return range;
};

const skipToRange = async (
  paragraph: Word.Range,
  paragraphEnd: Word.Range,
  value: string,
  context: Word.RequestContext
): Promise<Word.Range | null> => {
  let range: Word.Range | null = null;

  // Expand to the full paragraph range to ensure the entire paragraph is included
  const expandedParagraph = paragraph.expandTo(paragraphEnd);
  await context.sync();

  const searchResult = expandedParagraph.search(value, {});
  searchResult.load('items'); // Loading only the items for performance optimization
  await context.sync();

  // If search result has items, get the first match
  if (searchResult.items.length > 0) {
    range = searchResult.items[0].getRange();
    await context.sync();
  } else {
    console.warn(`No match found for value: "${value}" in paragraph`);
  }

  return range;
};

export const loadWordDocumentSections = createAsyncThunk(
  'office/LoadWordDocumentSections',
  async (_, { dispatch }) => {
    dispatch(setIsAnalyzing(true));

    await Word.run(async context => {
      // load as paragraphs to make it easier for plugin to match on suggestions
      const wordParagraphs = context.document.body.paragraphs;
      wordParagraphs.load(['text', 'style']);
      await context.sync();

      const sections: DocumentSection[] = [];
      const paragraphsNgram: IParagraph[] = [];
      let currentSection: DocumentSection = undefined;

      //detect if the paragraph is a heading
      const paragraphs = Array<string>();
      for (let i = 0; i < wordParagraphs.items.length; i++) {
        const text = wordParagraphs.items[i].text;
        if (text) {
          paragraphs.push(text);
        }

        const style = wordParagraphs.items[i].style;

        console.log('style', { style }, { text });
        if (style && style.includes('Heading')) {
          console.log('Heading', text);
          currentSection = {
            heading: text,
            paragraphs: [],
            isWaitingForCommentary: true,
            summary: '',
          };

          sections.push(currentSection);
          continue;
        }

        if (currentSection) {
          const paragraph: IParagraph = {
            text,
            index: i,
            nGrams: generateNGrams(text, NGRAM_LENGTH),
            sectionHeading: currentSection.heading,
          };
          // this is needed to make it easier to search for a given paragraph
          paragraphsNgram.push(paragraph); // Generate N-grams for each paragraph for matching later

          currentSection.paragraphs.push(paragraph);
        }
      }

      dispatch(
        sendMessage({
          action: 'SectionSummaryRequest',
          sections: sections.map(section => {
            return {
              header: section.heading,
              text: section.paragraphs.map(x => x.text).join('\n\n'),
            };
          }),
        })
      );

      dispatch(setDocumentSections(sections));

      dispatch(setSanitizedParagraphs(paragraphsNgram));

      context.document.load('changeTrackingMode');
      await context.sync();
      console.log('changeTrackingMode', context.document.changeTrackingMode);
      context.document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
      await context.sync();
    });
  }
);
export const loadWordDocument = createAsyncThunk(
  'office/LoadWordDocument',
  async (_, { dispatch }) => {
    dispatch(setIsAnalyzing(true));

    let fileName = await loadFileName();

    if (!fileName) {
      await Word.run(async context => {
        context.document.save('Prompt');
        await context.sync();
      });

      fileName = await loadFileName();
    }
    const document_text = await getDocumentContentAsTextV2();

    await Word.run(async context => {
      // load as text to send to backend for analysis
      const body = context.document.body;
      const wordParagraphs = context.document.body.paragraphs;

      wordParagraphs.load('text');
      body.load('text');
      await context.sync();
      const document_text = await getDocumentContentAsTextV2();
      const msg = {
        action: 'OpenWordRequest',
        document_name: fileName.split(`/`).pop(),
        document_text: document_text,
        document_paragraphs: wordParagraphs.items.map(x => x.text),
        attachment_id: localStorage.getItem('attachmentId') || null,
      };
      console.log('sending openword request', { msg });
      dispatch(sendMessage(msg));

      const paragraphs: IParagraph[] = [];
      for (let i = 0; i < wordParagraphs.items.length; i++) {
        const text = wordParagraphs.items[i].text;
        paragraphs.push({
          text,
          index: i,
          nGrams: generateNGrams(text, NGRAM_LENGTH),
        }); // Generate N-grams for each paragraph for matching later
      }
      //
      dispatch(setSanitizedParagraphs(paragraphs));
      // change tracking mode
      context.document.load('changeTrackingMode');
      await context.sync();

      console.log('changeTrackingMode', context.document.changeTrackingMode);
      context.document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
      await context.sync();
    });
  }
);

export const sendGlobalChangeRequest = createAsyncThunk(
  'office/GlobalChangeRequest',
  async (userInstructions: string, { dispatch, getState }) => {
    dispatch(setIsProcessingGlobalChanges(true));
    dispatch(setGlobalChangesProcessedResults(undefined));

    const state = getState() as RootState;

    await Word.run(async context => {
      // load as text to send to backend for analysis
      const body = context.document.body;
      body.load('text');
      await context.sync();

      // load as paragraphs to make it easier for plugin to match on suggestions
      const wordParagraphs = context.document.body.paragraphs;
      wordParagraphs.load('text');
      await context.sync();

      const paragraphs: IParagraph[] = [];
      for (let i = 0; i < wordParagraphs.items.length; i++) {
        const text = wordParagraphs.items[i].text;
        paragraphs.push({
          text,
          index: i,
          nGrams: generateNGrams(text, NGRAM_LENGTH),
        }); // Generate N-grams for each paragraph for matching later
      }

      // extrac all the paragraphs.text to string[] for backend
      const paragraphsText = paragraphs.map(x => x.text);
      dispatch(
        sendMessage({
          action: 'GlobalChangeWordRequest',
          user_command: userInstructions,
          document_id: state.office.documentId,
          open_document_body: paragraphsText,
        })
      );

      dispatch(setSanitizedParagraphs(paragraphs));

      // change tracking mode
      context.document.load('changeTrackingMode');
      await context.sync();
      console.log('changeTrackingMode', context.document.changeTrackingMode);
      context.document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
      await context.sync();
    });
  }
);

function splitWordsIntoBatches(text: string, batchSize = 200): string[] {
  const regex = new RegExp(
    `(.{1,${batchSize}}[\\.\\?!])|(.{1,${batchSize}}\\b)`,
    'g'
  );
  const batches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    batches.push(match[0]);
  }

  return batches;
}

export const sendAttachmentDocumentRequest = createAsyncThunk(
  'office/sendAttachmentDocumentRequest',
  async (_, { dispatch, getState }) => {
    try {
      const myFile = await getFileAsync(Office.FileType.Compressed, {
        sliceSize: 65536,
      });

      let fileName = await loadFileName();

      if (!fileName) {
        await Word.run(async context => {
          context.document.save('Prompt');
          await context.sync();
        });

        fileName = await loadFileName();
      }

      fileName = fileName.split(`/`).pop();

      const fileState = {
        file: myFile,
        counter: 0,
        sliceCount: myFile.sliceCount,
      };

      console.log(`Getting file of ${myFile.size} bytes`);
      let docData = [];
      for (let i = 0; i < fileState.sliceCount; i++) {
        const slice = await getSliceAsync(fileState.file, i);
        docData = docData.concat(slice.data);
        fileState.counter++;
      }

      await closeAsync(fileState.file);
      console.log('File closed.');

      //todo refactor to get backend to supply the sas key
      // using the Azure Blob Storage SDK initailize the connection with sas key
      const blobServiceClient = await getAzureBlobClient();

      // get the container
      // because we have not a identifier use guid

      //generate a guid for the container
      let documentUuid = uuidv4();
      let user = await getUser();
      const containerClient =
        blobServiceClient.getContainerClient('documentreview');

      //upload the bye array to the container
      const blockBlobClient = containerClient.getBlockBlobClient(
        `${user.id}/${documentUuid}/${fileName}`
      );
      const response = await blockBlobClient.uploadData(
        new Uint8Array(docData)
      );

      const state = getState() as RootState;

      const fileExtension = fileName.split('.').pop() || '';
      const documentType = getDocumentType(fileExtension);

      if (response._response.status === 201) {
        const urlWithoutSAS = blockBlobClient.url.split('?')[0];
        const req: UpdateDocumentAttachmentRequest = {
          action: RequestEnum.UpdateDocumentAttachmentRequest,
          document_attachment: {
            id: state.office.documentId,
            name: fileName,
            path: urlWithoutSAS,
            type: documentType,
            send: true,
          },
        };

        dispatch(sendMessage(req));
      }
    } catch (error) {
      console.error(error);
    }
  }
);

const getDocumentType = (extension: string): DocumentType => {
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'docx':
    case 'docm':
      return DocumentType.Word;
    case 'pdf':
      return DocumentType.PDF;
    default:
      console.warn(`Unsupported file type: ${extension}`);
      return DocumentType.Word; // Default to Word if unknown
  }
};

export const checkDocumentHasContent = createAsyncThunk(
  'office/checkDocumentHasContent',
  async (_, { dispatch }) => {
    await Word.run(async context => {
      const body = context.document.body;
      context.document.body.load('text');
      await context.sync();
      dispatch(setIsEmptyDocument(body.text.length === 0));
    });
  }
);

export const officeSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    setDocumentId: (state, action: PayloadAction<string>) => {
      state.documentId = action.payload;
    },
    setDocumentText: (state, action: PayloadAction<string>) => {
      state.documentText = action.payload;
    },
    addSuggestion: (state, action: PayloadAction<DocumentSuggestion>) => {
      state.suggestions.push(action.payload);
    },
    setAllSuggestions: (state, action: PayloadAction<DocumentSuggestion[]>) => {
      state.suggestions = action.payload;
    },
    setIsAnalyzing: (state, action: PayloadAction<boolean>) => {
      state.isAnalyzing = action.payload;
    },
    setPreventAnalyzing: (state, action: PayloadAction<boolean>) => {
      state.preventAnalyzing = action.payload;
    },
    clearSuggestions: state => {
      state.suggestions = [];
    },
    setSanitizedParagraphs: (state, action: PayloadAction<IParagraph[]>) => {
      state.sanitizedParagraphs = action.payload;
    },
    acceptSuggestion: (state, action: PayloadAction<DocumentSuggestion>) => {
      const suggestion = action.payload;
      const currentSuggestion = state.suggestions.find(
        s => s.suggestion_id === suggestion.suggestion_id
      );
      currentSuggestion.user_suggestion_action = 'accepted';
    },
    deleteSuggestion: (state, action: PayloadAction<DocumentSuggestion>) => {
      const suggestion = action.payload;
      const currentSuggestion = state.suggestions.find(
        s => s.suggestion_id === suggestion.suggestion_id
      );
      currentSuggestion.user_suggestion_action = 'deleted';
    },
    setIsProcessingGlobalChanges: (state, action: PayloadAction<boolean>) => {
      state.isProcessingGlobalChanges = action.payload;
    },
    setGlobalChanges: (
      state,
      action: PayloadAction<GlobalChangeWordResponse>
    ) => {
      state.globalChanges = action.payload;
    },
    setDocumentSections: (state, action: PayloadAction<DocumentSection[]>) => {
      state.documentSections = action.payload;
    },
    updateDocumentSectionSummary: (
      state: OfficeState,
      action: PayloadAction<SectionSummaryResponseChunk>
    ) => {
      const index = state.documentSections.findIndex(
        section => section.heading === action.payload.section_summary.header
      );
      const documentSection = state.documentSections[index];
      documentSection.summary = action.payload.section_summary.summary;
      documentSection.isWaitingForCommentary = false;
    },
    setGlobalChangesProcessedResults: (
      state,
      action: PayloadAction<GlobalChangeProcessedResults>
    ) => {
      state.globalChangesProcessedResults = action.payload;
    },
    setAttachmentSuccess: (state, action: PayloadAction<boolean>) => {
      state.attachmentSuccess = action.payload;
    },
    setIsEmptyDocument: (state, action: PayloadAction<boolean>) => {
      state.isEmptyDocument = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setActionsTab: (state, action: PayloadAction<string>) => {
      state.actionsTab = action.payload;
    },
    setGlobalChatMessages: (
      state,
      action: PayloadAction<{ role: string; message: string }[]>
    ) => {
      state.globalChatMessages = action.payload;
    },
  },
});

export const {
  setIsAnalyzing,
  addSuggestion,
  clearSuggestions,
  setPreventAnalyzing,
  setSanitizedParagraphs,
  acceptSuggestion,
  deleteSuggestion,
  setIsProcessingGlobalChanges,
  setGlobalChanges,
  setDocumentSections,
  updateDocumentSectionSummary,
  setGlobalChangesProcessedResults,
  setAttachmentSuccess,
  setDocumentId,
  setIsEmptyDocument,
  setCurrentTab,
  setGlobalChatMessages,
  setActionsTab,
  setAllSuggestions,
} = officeSlice.actions;
export default officeSlice.reducer;
