// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wDUsBGZsRVFUd2AOVpWv {\n  color: black;\n  font-size: 13px;\n  padding-left: 16px;\n  padding-right: 10px;\n  font-weight: 600;\n}\n.scpWdVaIllwqattmsynA {\n  margin-top: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/features/globalChanges/GlobalChange.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".globalTitle {\n  color: black;\n  font-size: 13px;\n  padding-left: 16px;\n  padding-right: 10px;\n  font-weight: 600;\n}\n.globalCnt {\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalTitle": "wDUsBGZsRVFUd2AOVpWv",
	"globalCnt": "scpWdVaIllwqattmsynA"
};
export default ___CSS_LOADER_EXPORT___;
