import * as React from 'react';
import styles from './Layout.module.css';
import { Link, Toaster } from '@fluentui/react-components';
import { tokenHelper } from '../../helper/tokenHelper';
import { useDispatch } from 'react-redux';
import { setIsLogin, setTokenState } from '../../store/socketSlice';
import { setChatMessages } from '../../store/reviewSlice';
import { initApp } from '../../store/rootReducer';
import {
  checkDocumentHasContent,
  loadWordDocument,
} from '../../store/officeSlice';
import { AppDispatch } from '../../store/store';
import { ChatContainer } from '../features/qcounsel/QCounsel';

export const GLOBAL_TOAST_ID = 'global';
interface LayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  styles?: React.CSSProperties;
}
export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    tokenHelper.getAccessToken(
      _ => {},
      false,
      accesstoken => {
        if (accesstoken) {
          dispatch(setTokenState(accesstoken));
          dispatch(setIsLogin(true));
          dispatch(initApp());
          dispatch(checkDocumentHasContent());
          dispatch(loadWordDocument());
          dispatch(setChatMessages([]));
        } else {
          dispatch(setIsLogin(false));
          console.log('you should login');
        }
      }
    );
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{props.header}</div>
      <div className={styles.main}>{props.children}</div>
      <div className={styles.footer}>
        {props.footer}
        <ChatContainer />
        <p>
          Your data is private and secure <Link href="#">Learn more</Link>
        </p>
      </div>
      <Toaster toasterId={GLOBAL_TOAST_ID} />
    </div>
  );
};
