import React, { useState } from 'react';
import styles from './ReviewTab.module.css';
import { Spinner } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewCategory, ReviewList } from './components/ReviewList';
import Chat from './components/Chat';
import IntialPage from './components/IntialPage';
import Header from './components/Header';
import { ReviewSteps, ReviewTabs } from './components/const';
import { Clauses } from './components/Clauses';
import DocumentSummary from './components/DocumentSummary';
import { AppDispatch, RootState } from '../../../store/store';

const ReviewTab = ({ onReturn }: any) => {
  const [setpNo, setStepNo] = useState(ReviewSteps.chat);
  const dispatch = useDispatch<AppDispatch>();
  // Scroll to the bottom whenever a new message is added
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const [reviewCategory, setReviewCategory] = useState<ReviewCategory>();
  const [tab, setTab] = useState(ReviewTabs.review);
  const [savedActions, setSavedActions] = useState({});
  const [savedLinkedActions, setSavedLinkedActions] = useState({}); //[currentIndex+category+link index]
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  const filteredSuggestions = suggestions.filter(
    x => x.category === reviewCategory
  );
  return (
    <div className={styles.reviewCnt}>
      <Header
        tab={tab}
        setTab={setTab}
        setpNo={setpNo}
        setStepNo={setStepNo}
        max={filteredSuggestions.length}
        value={
          Object.keys(savedActions).filter(
            key =>
              key.includes(reviewCategory) && savedActions?.[key] != undefined
          ).length
        }
      />
      {tab === ReviewTabs.review && setpNo == ReviewSteps.initial && (
        <IntialPage setStepNo={setStepNo} />
      )}
      {tab === ReviewTabs.review && setpNo == ReviewSteps.chat && (
        <Chat setStepNo={setStepNo} onReturn={onReturn} />
      )}
      {tab === ReviewTabs.review && setpNo == ReviewSteps.review && (
        <div className={styles.reviewCnt}>
          {isAnalyzing && <Spinner style={{ marginTop: '20px' }} />}
          {!isAnalyzing && (
            <>
              <div
                className={styles.reviewCntText}
                style={{ marginTop: '40px' }}
              >
                Review completed.
              </div>
              <ReviewList
                setStepNo={setStepNo}
                setReviewCategory={setReviewCategory}
              />
            </>
          )}
        </div>
      )}
      {tab === ReviewTabs.review && setpNo == ReviewSteps.clauses && (
        <Clauses
          reviewCategory={reviewCategory}
          setStepNo={setStepNo}
          savedActions={savedActions}
          setSavedActions={setSavedActions}
          savedLinkedActions={savedLinkedActions}
          setSavedLinkedActions={setSavedLinkedActions}
        />
      )}
      {tab === ReviewTabs.summary && <DocumentSummary />}
    </div>
  );
};

export default ReviewTab;
