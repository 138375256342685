import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import './index.css';
import { Provider } from 'react-redux';
import store, { persister } from '../store/store';
import { HomePage } from './App';

const rootElement: HTMLElement = document.getElementById('container');
const root = createRoot(rootElement);
const customTheme = {
  ...webLightTheme,
  fontFamilyBase: "'Arial', Helvetica, sans-serif", // Custom font
};
/* Render application after Office initializes */
Office.onReady(async () => {
  console.log('Rendering the React application...');
  renderApp();
});

function renderApp() {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <FluentProvider theme={customTheme}>
          <HomePage />
        </FluentProvider>
      </PersistGate>
    </Provider>
  );
}

/* Hot Module Replacement (HMR) setup */
if ((module as any).hot) {
  (module as any).hot.accept(() => {
    renderApp();
  });
}
