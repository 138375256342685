import { DocumentSuggestion } from '../models/review';

interface Clause {
  number: string;
  text: string;
}

export const replaceInText = (
  text: string | undefined,
  clauseObject: Record<string, Clause>
): string | undefined => {
  if (!text) return text;
  let escapedText = text.replace(/\./g, '__');
  const oldClauseNumbers = Object.keys(clauseObject);
  oldClauseNumbers.forEach(oldClauseNumber => {
    // Temporarily replace '.' with a placeholder character (like '__')
    const escapedOldClauseNumber = oldClauseNumber.replace(/\./g, '__'); // Replace dots with '__'
    const escapedReplacement = clauseObject[oldClauseNumber].number.replace(
      /\./g,
      '__'
    ); // Replace dots in the replacement
    const regex = new RegExp(`\\b${escapedOldClauseNumber}\\b`, 'g');

    // Check if the oldClauseNumber exists in the text
    if (regex.test(escapedText)) {
      // Replace the oldClauseNumber with the new clause number
      escapedText = escapedText.replace(
        regex,
        escapedReplacement + '__REPLACED__'
      );
    }
  });

  // After replacement, revert '__' back to '.' and remove the __REPLACED__ marker
  return escapedText.replace(/__REPLACED__/g, '').replace(/__/g, '.'); // Remove the __REPLACED__ marker
};
export function updateClauseNumbersinRefrences(
  suggestionObject: DocumentSuggestion,
  clauseObject: Record<string, Clause>
): DocumentSuggestion {
  // Create a deep copy of the suggestion object
  const clonedSuggestion = structuredClone
    ? structuredClone(suggestionObject)
    : JSON.parse(JSON.stringify(suggestionObject));

  // Helper function to replace old clause numbers in a string

  // Helper function to replace clause numbers in an array of strings
  const replaceInArray = (
    array: string[] | undefined
  ): string[] | undefined => {
    if (!array) return array;

    return array.map(item => replaceInText(item, clauseObject));
  };

  // Replace in top-level fields
  //clonedSuggestion.clause_number = replaceInText(clonedSuggestion.clause_number);
  clonedSuggestion.linked_clauses = replaceInArray(
    clonedSuggestion.linked_clauses
  );
  clonedSuggestion.note = replaceInText(clonedSuggestion.note, clauseObject);
  clonedSuggestion.suggested_text = replaceInText(
    clonedSuggestion.suggested_text,
    clauseObject
  );

  // Replace in nested `related_updates`
  if (
    clonedSuggestion.related_updates &&
    Array.isArray(clonedSuggestion.related_updates)
  ) {
    clonedSuggestion.related_updates = clonedSuggestion.related_updates.map(
      update => ({
        ...update,
        clause_number: replaceInText(update.clause_number, clauseObject),
        note: replaceInText(update.note, clauseObject),
        suggested_text: replaceInText(update.suggested_text, clauseObject),
      })
    );
  }

  return clonedSuggestion;
}
//-----------------------------------------------------
export function generateMapping(oldParagraphs, newParagraphs) {
  let mapping = {};

  oldParagraphs.forEach(oldParagraph => {
    const newParagraph = newParagraphs.find(p => p.text === oldParagraph.text);
    if (newParagraph) {
      mapping[oldParagraph.numbering] = {
        number: newParagraph.numbering,
        text: newParagraph.text,
      };
    }
  });
  return filterNumberKeys(mapping);
}

export function filterNumberKeys(mapping) {
  return Object.fromEntries(
    Object.entries(mapping)
      .filter(([key, _]) => {
        // Match keys that start with a number and values that end with a period
        return /^[0-9]/.test(key);
      })
      .map(([key, value]) => {
        // Remove the period at the end of the key if it exists
        const cleanedKey = key.endsWith('.') ? key : key + '.';
        const cleanedValue = String((value as any).number).endsWith('.')
          ? String((value as any).number)
          : (value as any).number + '.';
        return [
          cleanedKey,
          { text: (value as any).text, number: cleanedValue },
        ]; // Return the cleaned key and value
      })
  );
}

export async function extractParagraphs(context) {
  const paragraphs = context.document.body.paragraphs;
  paragraphs.load('items');
  await context.sync();

  let paragraphDetails = [];

  // Loop through paragraphs and load properties for valid ones
  for (const paragraph of paragraphs.items) {
    if (paragraph.isListItem) {
      paragraph.load('listItem');
      await context.sync();

      // Load the necessary properties of the listItem
      if (paragraph.listItem) {
        paragraph.listItem.load('listString');
        await context.sync();
        paragraphDetails.push({
          text: paragraph.text.trim(),
          numbering: paragraph.listItem?.listString || null, // Use null if listString is unavailable
        });
      }
    }
  }

  return paragraphDetails;
}
