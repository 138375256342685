import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styles from './Clauses.module.css';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import diff from 'diff-match-patch';

import classNames from 'classnames/bind';
import {
  applySuggestion,
  scrollToSuggestion,
  setAllSuggestions,
  setPreventAnalyzing,
} from '../../../../store/officeSlice';
import { OFFICE_ENCODING_COMMENT } from '../../../../helper/office';
import {
  setchatSuggestMessages,
  setViewHeader,
} from '../../../../store/reviewSlice';
import { DocumentSuggestion } from '../../../../models/review';
import SuggestChat from './SuggestChat';
import { ReviewSteps } from './const';
import { ReviewCategory } from './ReviewList';
import {
  extractParagraphs,
  generateMapping,
  updateClauseNumbersinRefrences,
} from '../../../../helper/missingClause';
import { Spinner } from '@fluentui/react-components';
import { Dismiss16Regular, Edit16Regular } from '@fluentui/react-icons';
import { TextField } from '@fluentui/react';
import { ConfirmMessage } from './ConfirmMessage';

//refer to the headings for the clauses labels
const clauseActions = {
  dismiss: 'dismiss',
  keep: 'keep',
  insert: 'insert',
};
export const Clauses = ({
  reviewCategory,
  setStepNo,
  savedActions,
  setSavedActions,
  savedLinkedActions,
  setSavedLinkedActions,
  suggestions,
}: {
  reviewCategory: ReviewCategory;
  setStepNo: any;
  savedActions: any;
  setSavedActions: any;
  savedLinkedActions: any;
  setSavedLinkedActions: any;
  suggestions: DocumentSuggestion[];
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showPop, setShowPop] = useState(false);
  const [suggestAlt, setSuggestAlt] = useState(false);
  const [suggestAlt2, setSuggestAlt2] = useState<any>(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [newSuggest, setNewSuggest] = useState(undefined);
  const [linkNewSuggest, setLinkNewSuggest] = useState({}); //state for changes by link clause index
  const [clauseAction, setClauseAction] = useState(undefined);
  const [linkClauseAction, setLinkClauseAction] = useState({}); //state action by link clause index
  const [isEditableClause, setisEditableClause] = useState(false);
  const [isEditableClauseLinks, setisEditableClauseLinks] = useState({});

  let chatSuggestMessages = useSelector(
    (state: RootState) => state.review.chatSuggestMessages
  );
  const [loadInsertion, setLoadInsertion] = useState(false);
  const filteredSuggestions = React.useMemo(() => {
    return suggestions.filter(x => x.category === reviewCategory);
  }, [suggestions]);
  /*   const [currentSuggestionPreview, setCurrentSuggestionPreview] =
    React.useState('');
 */

  const [currentSuggestion, setCurrentSuggestion] =
    React.useState<DocumentSuggestion>(filteredSuggestions[0]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] =
    React.useState<number>(0);

  // @ts-ignore
  // const [setShowReviewButton] = useOutletContext();
  const currentSuggestionPreview = React.useMemo(() => {
    if (currentSuggestion) {
      let current_value = currentSuggestion.original_text || '';
      let applying_value = newSuggest
        ? newSuggest
        : currentSuggestion.suggested_text
        ? currentSuggestion.suggested_text
        : '';
      const dmp = new diff.diff_match_patch();
      let diffs;
      if (reviewCategory == ReviewCategory.Acceptable && applying_value == '') {
        return '';
      } else {
        diffs = dmp.diff_main(
          current_value.replace(OFFICE_ENCODING_COMMENT, ''),
          applying_value.replace(OFFICE_ENCODING_COMMENT, '')
        );
      }

      dmp.diff_cleanupSemantic(diffs);
      return dmp.diff_prettyHtml(diffs);
    } else return '';
  }, [newSuggest, currentSuggestion]);

  const currentSuggestionPreviewLink = React.useMemo(() => {
    let output = {};
    if (currentSuggestion?.related_updates?.length > 0) {
      for (let linkIndex in Object.keys(currentSuggestion.related_updates)) {
        let suggest = currentSuggestion.related_updates[linkIndex];
        let applying_value = linkNewSuggest[linkIndex]
          ? linkNewSuggest[linkIndex]
          : suggest.suggested_text ?? '';
        const dmp = new diff.diff_match_patch();
        const diffs = dmp.diff_main(
          suggest.original_text.replace(OFFICE_ENCODING_COMMENT, ''),
          applying_value.replace(OFFICE_ENCODING_COMMENT, '')
        );
        dmp.diff_cleanupSemantic(diffs);
        output[linkIndex] = dmp.diff_prettyHtml(diffs);
      }
      return output;
    } else return {};
  }, [currentSuggestion, linkNewSuggest]);
  const cx = classNames.bind(styles);
  const className = cx({
    clauseText: true,
    unacceptable: reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: reviewCategory === ReviewCategory.NonStandard,
    acceptable: reviewCategory === ReviewCategory.Acceptable,
    missing: reviewCategory === ReviewCategory.Missing,
  });

  useEffect(() => {
    dispatch(setPreventAnalyzing(true));
    if (currentSuggestion) {
      dispatch(scrollToSuggestion(currentSuggestion));
    }
  }, [currentSuggestion]);

  useEffect(() => {
    //using the  original text find the heading of the clause from the document sections,
    if (savedActions[currentSuggestionIndex + reviewCategory]) {
      setClauseAction(savedActions[currentSuggestionIndex + reviewCategory]);
    }
    if (currentSuggestion?.related_updates) {
      for (let linkIndex in Object.keys(currentSuggestion.related_updates)) {
        if (
          savedLinkedActions[
            currentSuggestionIndex + reviewCategory + linkIndex
          ]
        ) {
          setLinkClauseAction(prev => {
            return {
              ...prev,
              [linkIndex]:
                savedLinkedActions[
                  currentSuggestionIndex + reviewCategory + linkIndex
                ],
            };
          });
        }
      }
    }
  }, [currentSuggestion]);
  useEffect(() => {
    if (reviewCategory === undefined) {
      //setStepNo(ReviewSteps.review);
    }
  }, [reviewCategory]);
  const navigateToNextSuggestion = () => {
    if (currentSuggestionIndex < filteredSuggestions.length - 1) {
      const suggestion = filteredSuggestions[currentSuggestionIndex + 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  const navigateToPreviousSuggestion = () => {
    if (currentSuggestionIndex > 0) {
      const suggestion = filteredSuggestions[currentSuggestionIndex - 1];
      dispatch(scrollToSuggestion(suggestion));
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };

  if (!currentSuggestion) {
    return null;
  }

  const clauseRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    dispatch(setViewHeader(false));
    return () => {
      dispatch(setViewHeader(true));
    };
  }, []);

  const cleanFlags = () => {
    setClauseAction(undefined);
    setNewSuggest(undefined);
    setLinkClauseAction({});
    setLinkNewSuggest({});
    setisEditableClause(false);
    setisEditableClauseLinks({});
  };

  function insertTextAtCursor(input: string) {
    setLoadInsertion(true);
    let text = input
      // Replace double newlines with a single newline
      // Replace double newlines with a single newline
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      //.replace(/^(\d+\.)/gm, '\n$1') // Ensure each numbered item starts on a new line
      .trim();
    let current_new_suggestions = { ...currentSuggestion };
    //console.log(currentSuggestion);
    Word.run(async context => {
      // Get the current selection (cursor position)
      let old_numbering = await extractParagraphs(context);
      const range = context.document.getSelection();
      const paragraph = range.paragraphs.getFirst();
      paragraph.load('text');
      await context.sync();
      // If the current paragraph is not empty, insert a new line before the clause
      if (paragraph.text.trim().length > 0) {
        range.insertParagraph('', Word.InsertLocation.after);
      }
      range.insertText(
        text.replace(/\n/g, '\t\v\t\v   $1'),
        //.replace(/(\d+\.\s)/g, '\t\v\t\v   $1'),
        Word.InsertLocation.end
      );

      await context.sync();
      let new_numbering = await extractParagraphs(context);
      let mapping = generateMapping(old_numbering, new_numbering);
      //console.log(mapping, new_numbering);
      let arr = suggestions.map(item => ({ ...item }));
      for (let index in suggestions) {
        let suggestion = suggestions[index];
        if (suggestion.suggestion_id === currentSuggestion.suggestion_id) {
          let text_missing = text.replace(/\n/g, '\t\v\t\v   $1');

          arr[index] = updateClauseNumbersinRefrences(
            suggestions[index],
            mapping
          );
          arr[index].clause_number =
            new_numbering.filter(n =>
              n.text?.includes(text_missing.slice(0, 150))
            )[0]?.numbering || 'N/A';
          //console.log(arr[index]);
          current_new_suggestions = { ...arr[index] };
        } else {
          let old_clause_number = suggestion.clause_number;
          arr[index] = updateClauseNumbersinRefrences(
            suggestions[index],
            mapping
          );
          if (
            mapping[old_clause_number] &&
            suggestion.original_text?.includes(
              mapping[old_clause_number].text.slice(0, 70)
            )
          ) {
            let new_clause_number = mapping[old_clause_number].number;
            arr[index].clause_number = new_clause_number;
          }
        }
      }
      dispatch(setAllSuggestions(arr));
      setCurrentSuggestion(current_new_suggestions);
      setLoadInsertion(false);
      setClauseAction(clauseActions.insert);
    }).catch(function (error) {
      console.log('Error: ' + error);
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        setLoadInsertion(false);
      }
    });
  }

  function removeClauseByText(text: string) {
    setLoadInsertion(true);

    Word.run(async context => {
      const body = context.document.body;
      const normalizedText = text.replace(/\s+/g, ' ').trim();
      const firstLine = normalizedText
        .slice(0, 100)
        .replace(/\n/g, '\t\v\t\v   $1');
      // Extract the first line from the provided text

      // Search for the first line text in the document
      const searchResults = body.search(firstLine, {
        matchCase: false,
        matchPrefix: true,
      });
      //searchResults.load('items');
      searchResults.load('items, items/paragraphs');
      await context.sync();
      //console.log(searchResults);
      if (searchResults.items.length > 0) {
        let old_numbering = await extractParagraphs(context);
        let current_new_suggestions = { ...currentSuggestion };
        // Delete the paragraph containing the found text
        searchResults.items[0].paragraphs.getFirst().delete();
        await context.sync();
        console.log('Clause and numbering removed.');
        let new_numbering = await extractParagraphs(context);
        let mapping = generateMapping(old_numbering, new_numbering);
        let arr = suggestions.map(item => ({ ...item }));
        for (let index in suggestions) {
          let suggestion = suggestions[index];
          if (suggestion.suggestion_id === currentSuggestion.suggestion_id) {
            arr[index] = updateClauseNumbersinRefrences(
              suggestions[index],
              mapping
            );
            arr[index].clause_number = 'N/A';
            current_new_suggestions = { ...arr[index] };
          } else {
            let old_clause_number = suggestion.clause_number;
            arr[index] = updateClauseNumbersinRefrences(
              suggestions[index],
              mapping
            );
            if (
              mapping[old_clause_number] &&
              suggestion.original_text.includes(
                mapping[old_clause_number].text.slice(0, 70)
              )
            ) {
              let new_clause_number = mapping[old_clause_number].number;
              arr[index].clause_number = new_clause_number;
            }
          }
        }
        dispatch(setAllSuggestions(arr));
        setCurrentSuggestion(current_new_suggestions);
        setLoadInsertion(false);
      } else {
        console.log('Clause not found.');
        setLoadInsertion(false);
      }
    }).catch(function (error) {
      console.log('Error: ' + error);
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
        setLoadInsertion(false);
      }
    });
  }
  if (showPop)
    return (
      <SuggestChat
        onApply={() => {
          //update current suggestion based on alt1 (normal clause) or alt2 (link clause)
          // setnewsuggest to latest message in suggest
          let suggest = chatSuggestMessages[
            chatSuggestMessages.length - 1
          ].message.replace('Suggestion:', '');
          if (suggestAlt) {
            setNewSuggest(suggest);
          }
          if (suggestAlt2) {
            setLinkNewSuggest(prev => {
              return { ...prev, [suggestAlt2]: suggest };
            });
          }
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        onClose={() => {
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        current_clause={
          suggestAlt
            ? currentSuggestion?.original_text
            : currentSuggestion?.related_updates[suggestAlt2].original_text
        }
        ai_clause={
          suggestAlt
            ? currentSuggestion?.suggested_text
            : currentSuggestion?.related_updates[suggestAlt2].suggested_text
        }
      />
    );

  return (
    <div className={styles.clauseContainer}>
      <div className={styles.clauseHeader}>
        <div className={styles.clausetitle}>{`${
          currentSuggestionIndex + 1
        }.  ${reviewCategory} Clause ${currentSuggestion.clause_number}`}</div>
        <div className={styles.clauseValue}></div>
        <div className={styles.nav}>
          {currentSuggestionIndex > 0 && (
            <div
              ref={clauseRef}
              className={styles.clauseNav}
              onClick={() => {
                if (clauseAction) {
                  setSavedActions(prev => {
                    return {
                      ...prev,
                      [currentSuggestionIndex + reviewCategory]: clauseAction,
                    };
                  });
                }

                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToPreviousSuggestion();
              }}
            >
              {'<'}
            </div>
          )}

          <div className={styles.page}>{`${currentSuggestionIndex + 1} of ${
            filteredSuggestions.length
          }`}</div>
          {currentSuggestionIndex < filteredSuggestions.length - 1 && (
            <div
              className={styles.clauseNav}
              onClick={() => {
                setSavedActions(prev => {
                  return {
                    ...prev,
                    [currentSuggestionIndex + reviewCategory]: clauseAction,
                  };
                });
                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToNextSuggestion();
              }}
            >
              {'>'}
            </div>
          )}
        </div>
      </div>
      <div className={styles.commentaryHeading}>Commentary:</div>
      <div
        className={
          clauseAction === clauseActions.dismiss
            ? `${className} ${styles.disabledText}`
            : className
        }
        style={{ minHeight: '50px' }}
      >
        <div>{currentSuggestion.note}</div>

        <div>
          {currentSuggestion.linked_clauses?.length > 0 && (
            <div className={styles.linkTitle}>List of linked clauses:</div>
          )}
          {currentSuggestion.linked_clauses.map(c => {
            return (
              <div className={styles.linkItem} key={c}>
                {c}
              </div>
            );
          })}
        </div>
      </div>

      {![ReviewCategory.Missing].includes(reviewCategory) && (
        <button
          className={styles.rightButton}
          onClick={() => {
            //setDismiss(prev => !prev)
            setisEditableClause(false);
            if (clauseAction === clauseActions.dismiss) {
              setClauseAction(undefined);
              setLinkClauseAction({});
            } else {
              if (clauseAction === clauseActions.insert) {
                //remove insert effect
                let suggest = { ...currentSuggestion };
                let current_text = newSuggest || suggest.suggested_text;
                suggest.suggested_text = suggest.original_text;
                suggest.original_text = current_text;
                dispatch(applySuggestion(suggest));
              }

              setClauseAction(clauseActions.dismiss);
              setLinkClauseAction(prev => {
                let dismissObject = {};
                for (let index in Object.keys(
                  currentSuggestion.related_updates
                )) {
                  if (prev?.[index] == clauseActions.insert) {
                    let suggest = {
                      ...(currentSuggestion.related_updates[index] as any),
                    };
                    if (linkNewSuggest[index]) {
                      suggest.original_text = linkNewSuggest[index];
                    }
                    suggest.suggested_text = suggest.original_text;
                    dispatch(applySuggestion(suggest));
                  }
                  dismissObject[index] = clauseActions.dismiss;
                }
                return dismissObject;
              });
            }
          }}
        >
          {clauseAction == clauseActions.dismiss ? 'Edit' : 'Dismiss'}
        </button>
      )}
      <div className={styles.commentaryHeading}>
        Suggested Language for Clause {currentSuggestion.clause_number}
      </div>
      <div className={styles.editCnt}>
        {!clauseAction && (
          <div
            className={styles.editIcon}
            onClick={() => {
              setisEditableClause(prev => !prev);
            }}
          >
            {isEditableClause ? <Dismiss16Regular /> : <Edit16Regular />}
          </div>
        )}
        {isEditableClause ? (
          <TextField
            className={styles.commentary}
            value={newSuggest || currentSuggestion.suggested_text}
            disabled={clauseAction}
            multiline
            autoAdjustHeight
            onClick={() => {
              dispatch(scrollToSuggestion(currentSuggestion));
            }}
            onChange={(_, new_value) => {
              setNewSuggest(new_value);
            }}
            styles={{
              fieldGroup: { border: 'none' },
              field: {
                fontSize: '0.687rem',
                color: '#545454',
                resize: 'none',
                border: 'none',
              },
              root: { border: 'none', resize: 'none' },
            }}
          />
        ) : (
          <div
            className={styles.commentary}
            dangerouslySetInnerHTML={{
              __html: currentSuggestionPreview,
            }}
            onClick={() => {
              dispatch(scrollToSuggestion(currentSuggestion));
            }}
            style={
              reviewCategory == ReviewCategory.Missing
                ? { minHeight: '160px' }
                : {}
            }
          ></div>
        )}
      </div>
      <div className={styles.btnCnt}>
        <button
          className={
            [clauseActions.keep, clauseActions.insert].includes(clauseAction)
              ? styles.rightButton
              : styles.mainButton
          }
          disabled={clauseAction === clauseActions.dismiss || loadInsertion}
          onClick={() => {
            setisEditableClause(false);
            if (clauseAction) {
              setClauseAction(undefined);
              if (reviewCategory == ReviewCategory.Missing) {
                if (newSuggest) {
                  removeClauseByText(newSuggest);
                } else {
                  removeClauseByText(currentSuggestion.suggested_text);
                }
                return;
              }
              //remove insert effect
              let suggest = { ...currentSuggestion };
              let current_text = newSuggest || suggest.suggested_text;
              suggest.suggested_text = suggest.original_text;
              suggest.original_text = current_text;
              dispatch(applySuggestion(suggest));
            }
            if (!clauseAction) {
              setClauseAction(clauseActions.keep);
            }
          }}
        >
          {clauseAction ? (
            loadInsertion ? (
              <Spinner size="extra-tiny" />
            ) : (
              'Edit'
            )
          ) : reviewCategory == ReviewCategory.Missing ? (
            'Ignore'
          ) : (
            'Keep Existing'
          )}
        </button>
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={clauseAction === clauseActions.dismiss || loadInsertion}
            onClick={() => {
              //think should set current clause and current linked clause or get it from index
              //go to chat pop
              setShowPop(true);
              setSuggestAlt(true);
              dispatch(setchatSuggestMessages([]));
            }}
          >
            Suggest Alternative
          </button>
        )}
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={clauseAction === clauseActions.dismiss || loadInsertion}
            onClick={async () => {
              setisEditableClause(false);
              if (reviewCategory === ReviewCategory.Missing) {
                insertTextAtCursor(
                  newSuggest || currentSuggestion.suggested_text
                );
                setClauseAction(clauseActions.insert);
                return;
              }
              let suggest = { ...currentSuggestion };
              if (newSuggest) {
                suggest.suggested_text = newSuggest;
              }
              dispatch(applySuggestion(suggest));
              setClauseAction(clauseActions.insert);
            }}
          >
            {reviewCategory === ReviewCategory.Missing ? (
              loadInsertion ? (
                <Spinner size="extra-tiny" />
              ) : (
                'Insert at Cursor'
              )
            ) : (
              'Insert in Document'
            )}
          </button>
        )}
      </div>
      {/* ------------------------------------------------------------------------------------------------------------- */}
      <>
        {currentSuggestion.related_updates.map((linkClause, linkIndex) => {
          return (
            <>
              <div className={styles.commentaryHeading}>
                Suggested Language for Linked Clause {linkClause.clause_number}
              </div>
              <div className={styles.editCnt}>
                {!linkClauseAction?.[linkIndex] && (
                  <div
                    className={styles.editIcon}
                    onClick={() => {
                      setisEditableClauseLinks(prev => {
                        let prev_state = prev[linkIndex] ?? false;
                        return { ...prev, [linkIndex]: !prev_state };
                      });
                    }}
                  >
                    {isEditableClauseLinks[linkIndex] ? (
                      <Dismiss16Regular />
                    ) : (
                      <Edit16Regular />
                    )}
                  </div>
                )}
                {isEditableClauseLinks[linkIndex] ? (
                  <TextField
                    className={styles.commentary}
                    value={
                      linkNewSuggest?.[linkIndex] || linkClause.suggested_text
                    }
                    disabled={linkClauseAction?.[linkIndex]}
                    multiline
                    autoAdjustHeight
                    onClick={() => {
                      //console.log(currentSuggestion.related_updates[linkIndex]);
                      dispatch(
                        scrollToSuggestion(
                          currentSuggestion.related_updates[linkIndex]
                        )
                      );
                    }}
                    onChange={(_, new_value) => {
                      setLinkNewSuggest(prev => {
                        return {
                          ...prev,
                          [linkIndex]: new_value,
                        };
                      });
                    }}
                    styles={{
                      fieldGroup: { border: 'none' },
                      field: {
                        fontSize: '0.687rem',
                        color: '#545454',
                        resize: 'none',
                        border: 'none',
                      },
                      root: { border: 'none', resize: 'none' },
                    }}
                  />
                ) : (
                  <div
                    className={styles.commentary}
                    dangerouslySetInnerHTML={{
                      __html: currentSuggestionPreviewLink[linkIndex] || '',
                    }}
                    onClick={() => {
                      dispatch(
                        scrollToSuggestion(
                          currentSuggestion.related_updates[linkIndex]
                        )
                      );
                    }}
                    style={
                      reviewCategory == ReviewCategory.Missing
                        ? { minHeight: '160px' }
                        : {}
                    }
                  ></div>
                )}
              </div>

              <div className={styles.btnCnt}>
                <button
                  className={
                    linkClauseAction?.[linkIndex] &&
                    [clauseActions.keep, clauseActions.insert].includes(
                      linkClauseAction[linkIndex]
                    )
                      ? styles.rightButton
                      : styles.mainButton
                  }
                  disabled={
                    linkClauseAction?.[linkIndex] === clauseActions.dismiss
                  }
                  onClick={() => {
                    if (linkClauseAction?.[linkIndex]) {
                      //remove insert effect
                      if (
                        linkClauseAction[linkIndex] === clauseActions.insert
                      ) {
                        let suggest = {
                          ...(currentSuggestion.related_updates[
                            linkIndex
                          ] as any),
                        };
                        if (linkNewSuggest[linkIndex]) {
                          suggest.original_text = linkNewSuggest[linkIndex];
                        }
                        suggest.suggested_text = suggest.original_text;
                        dispatch(applySuggestion(suggest));
                      }

                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: undefined,
                        };
                      });
                    }
                    if (!linkClauseAction?.[linkIndex]) {
                      setisEditableClauseLinks(prev => {
                        return { ...prev, [linkIndex]: false };
                      });
                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: clauseActions.keep,
                        };
                      });
                    }
                  }}
                >
                  {linkClauseAction?.[linkIndex] ? 'Edit' : 'Keep Existing'}
                </button>
                {![clauseActions.insert, clauseActions.keep].includes(
                  linkClauseAction?.[linkIndex]
                ) && (
                  <button
                    className={styles.mainButton}
                    disabled={linkClauseAction?.[linkIndex]}
                    onClick={() => {
                      //think should set current clause and current linked clause or get it from index
                      //go to chat pop
                      setShowPop(true);
                      setSuggestAlt2(linkIndex);
                      setSuggestAlt(false);
                      dispatch(setchatSuggestMessages([]));
                    }}
                  >
                    Suggest Alternative
                  </button>
                )}
                {![clauseActions.insert, clauseActions.keep].includes(
                  linkClauseAction?.[linkIndex]
                ) && (
                  <button
                    className={styles.mainButton}
                    disabled={
                      linkClauseAction?.[linkIndex] == clauseActions.dismiss
                    }
                    onClick={() => {
                      setisEditableClauseLinks(prev => {
                        return { ...prev, [linkIndex]: false };
                      });
                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: clauseActions.insert,
                        };
                      });
                      let suggest = {
                        ...(currentSuggestion.related_updates[
                          linkIndex
                        ] as any),
                      };
                      if (linkNewSuggest[linkIndex]) {
                        suggest.suggested_text = linkNewSuggest[linkIndex];
                      }
                      dispatch(applySuggestion(suggest));
                    }}
                  >
                    Insert in Document
                  </button>
                )}
              </div>
            </>
          );
        })}
        {/* -------------------------------------------------------------------------- */}
        {currentSuggestion.legal_research && (
          <>
            <div className={styles.commentaryHeading}>Legal Research</div>
            <div
              className={
                clauseAction === clauseActions.dismiss
                  ? `${styles.commentary} ${styles.disabledText}`
                  : styles.commentary
              }
            >
              <div> {currentSuggestion.legal_research?.summary}</div>
              <div>
                {' '}
                {currentSuggestion.legal_research?.sources.map(s => {
                  return (
                    <div key={s.link}>
                      <a href={s.link} target="_blank" key={s.link}>
                        {s.title}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </>
      <div
        className={styles.btnCnt}
        style={{ marginTop: 'auto', paddingTop: '10px' }}
      >
        <button
          className={styles.acceptBtn}
          disabled={clauseAction === clauseActions.dismiss}
          onClick={() => {
            //insert all main clauses this handling for next clauses
            for (let index in filteredSuggestions) {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [index + reviewCategory]: clauseActions.insert,
                };
              });
              let suggest = { ...filteredSuggestions[index] };
              dispatch(applySuggestion(suggest));
              for (let linkIndex in filteredSuggestions[index]
                .related_updates) {
                setSavedLinkedActions(prev => {
                  return {
                    ...prev,
                    [index + reviewCategory + linkIndex]: clauseActions.insert,
                  };
                });
                dispatch(
                  applySuggestion(
                    filteredSuggestions[index].related_updates[linkIndex] as any
                  )
                );
              }
            }
            cleanFlags();
            setStepNo(ReviewSteps.review);
          }}
        >
          Accept all
        </button>
        <button
          className={styles.nextBtn}
          onClick={() => {
            //save clause action before leave
            let allKeys = Object.keys(currentSuggestion.related_updates);
            let linkClauseActionsValues = [];
            for (let key of allKeys) {
              let value = linkClauseAction[key] ?? undefined;
              linkClauseActionsValues.push(value);
            }
            let linkClauseActionsKeys = Object.keys(linkClauseAction);
            if (
              !clauseAction ||
              (linkClauseActionsValues.includes(undefined) &&
                reviewCategory !== ReviewCategory.Missing)
            ) {
              setShowConfirmSave(true);
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]: clauseAction,
                };
              });
              setSavedLinkedActions(prev => {
                let result = { ...prev };
                for (let linkIndex of linkClauseActionsKeys) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction?.[linkIndex];
                }
                return result;
              });
              cleanFlags();
              navigateToNextSuggestion();
              if (currentSuggestionIndex == filteredSuggestions.length - 1) {
                //finish case all category clauses
                setStepNo(ReviewSteps.review);
              }
            }
          }}
        >
          {currentSuggestionIndex < filteredSuggestions.length - 1
            ? 'Next'
            : 'Finish'}
        </button>
      </div>
      {/* --------------------------------------------------------------------------------------------- */}
      {showConfirmSave &&
        ConfirmMessage(
          styles,
          () => {
            //on discard save action as it was dismissed
            setSavedActions(prev => {
              return {
                ...prev,
                [currentSuggestionIndex + reviewCategory]: undefined,
              };
            });
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              for (let linkIndex in linkClauseAction) {
                if (prev[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                }
              }
              return result;
            });
            cleanFlags();
            setShowConfirmSave(false);
            //go to next clause if no next go to review categories list
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          },
          () => {
            // on click Insert
            //save action was insert
            if (!clauseAction) {
              let suggest = { ...currentSuggestion };

              if (newSuggest) {
                suggest.suggested_text = newSuggest;
              }
              dispatch(applySuggestion(suggest));
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
            }

            //make insertion for any link clause have no action then save action as insert
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              let allKeys = Object.keys(currentSuggestion.related_updates);
              for (let linkIndex of allKeys) {
                if (linkClauseAction[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                } else {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    clauseActions.insert;
                  let suggest = {
                    ...currentSuggestion.related_updates[linkIndex],
                  };
                  if (linkNewSuggest[linkIndex]) {
                    suggest.suggested_text = linkNewSuggest[linkIndex];
                  }
                  dispatch(applySuggestion(suggest));
                }
              }
              return result;
            });
            //remove message
            setShowConfirmSave(false);
            cleanFlags();
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          }
        )}
    </div>
  );
};
