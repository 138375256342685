import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useState } from 'react';
import DocumentSummary from '../features/summary/DocumentSummary';
import EmailSummary from '../features/summary/EmailSummary';

const SummaryPage = () => {
  const styles = useStyles();
  const [isEmail, setIsEmail] = useState(false);

  return (
    <div className={styles.pageCnt}>
      <div className={styles.header}>
        <div
          className={isEmail ? styles.tabItem : styles.tabItemActive}
          onClick={() => {
            setIsEmail(false);
          }}
        >
          Document Summary
        </div>
        <div
          className={isEmail ? styles.tabItemActive : styles.tabItem}
          onClick={() => {
            setIsEmail(true);
          }}
        >
          AI Email Reply
        </div>
      </div>
      {!isEmail && <DocumentSummary />}
      {isEmail && <EmailSummary />}
    </div>
  );
};

export default SummaryPage;

const useStyles = makeStyles({
  pageCnt: {
    ...shorthands.padding('5vw', '15px'),
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  tabItem: {
    fontSize: '12px',
    fontFamily: 'Arial',
    color: '#696969',
    cursor: 'pointer',
  },
  tabItemActive: {
    fontSize: '12px',
    fontFamily: 'Arial',
    color: '#0387F5',
    ...shorthands.borderBottom('1px', 'solid', '#0387F5'),
    cursor: 'pointer',
  },
});
