import React from 'react';
import styles from './GlobalChange.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  sendGlobalChangeRequest,
  setGlobalChatMessages,
} from '../../../store/officeSlice';
import BotChating from '../../shared/botChating/BotChating';

const GlobalChange = ({}: any) => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch<AppDispatch>();
  const globalChatMessages: { role: string; message: string }[] = useSelector(
    (state: RootState) => state.office.globalChatMessages //alternative messages messages
  );
  const isProcessingGlobalChanges: boolean = useSelector(
    (state: RootState) => state.office.isProcessingGlobalChanges //alternative messages messages
  );

  const handleSubmit = () => {
    let chat = [
      ...globalChatMessages,
      {
        role: 'user',
        message: value,
      },
    ];
    dispatch(setGlobalChatMessages(chat));
    dispatch(sendGlobalChangeRequest(value));
  };
  return (
    <div className={styles.globalCnt}>
      <div className={styles.globalTitle}>Global Changes</div>
      <BotChating
        loading={isProcessingGlobalChanges}
        onMessageSend={() => {
          handleSubmit();
        }}
        chatMessages={globalChatMessages}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default GlobalChange;
