import { BaseRequest, BaseResponse, ResponseEnum } from './base';

export enum MessageRole {
  User = 'user',
  Assistant = 'assistant',
  Attach = 'attach',
}

export interface ChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  attached: string;
  docs?: string[];
  is_yes_no?: boolean;
  is_apply_regenrate?: boolean;
  is_dropdown?: boolean;
}
export interface ReviewChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  is_finished?: boolean;
}
export interface SuggestChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  is_question?: boolean;
  is_suggestion_message?: boolean;
}
export interface ChatWordRequest extends BaseRequest {
  messages: ChatMessage[];
  thread_id: string;
  open_document_body?: string;
}
export interface ReviewDocumentChatRequest extends BaseRequest {
  messages: ReviewChatMessage[];
  thread_id: string;
  document_text?: string;
  document_id: string;
}
export interface ReviewDocumentSuggestChatRequest extends BaseRequest {
  messages: ReviewChatMessage[];
  thread_id: string;
  document_text?: string;
  current_clause?: string;
  ai_suggested_clause?: string;
}

export interface ChatWordResponse extends BaseResponse {
  response: string;
  thread_id: string;
  docs: string[];
}
export interface ReviewDocumentChatResponse extends BaseResponse {
  response: string;
  thread_id: string;
  is_finished: boolean;
}
export interface SuggestAlternativeChatResponse extends BaseResponse {
  response: string;
  thread_id: string;
  is_question?: boolean;
  is_suggestion_message?: boolean;
}
export interface ReviewDocumentChatResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  is_finished: boolean;
}
export interface SuggestAlternativeChatResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  is_question?: boolean;
  is_suggestion_message?: boolean;
}
export interface ChatWordResponseChunk extends BaseResponse {
  response: string;
  thread_id: string;
  docs: string[];
}

export const isChatWordResponse = (
  response: any
): response is ChatWordResponse => {
  return response.action === ResponseEnum.ChatWordResponse;
};

export const isChatWordResponseChunk = (
  response: any
): response is ChatWordResponseChunk => {
  return response.action === ResponseEnum.ChatWordResponseChunk;
};

export const isReviewDocumentChatResponseChunk = (
  response: any
): response is ReviewDocumentChatResponseChunk => {
  return response.action === ResponseEnum.ReviewDocumentChatResponseChunk;
};
export const isReviewDocumentChatResponse = (
  response: any
): response is ReviewDocumentChatResponse => {
  return response.action === ResponseEnum.ReviewDocumentChatResponse;
};

export const isReviewDocumentSuggestAlternativeChatResponseChunk = (
  response: any
): response is SuggestAlternativeChatResponseChunk => {
  return response.action === ResponseEnum.SuggestAlternativeChatResponseChunk;
};
export const isReviewDocumentSuggestAlternativeChatResponse = (
  response: any
): response is SuggestAlternativeChatResponse => {
  return response.action === ResponseEnum.SuggestAlternativeChatResponse;
};
