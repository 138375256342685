import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState, AppDispatch } from '../../../store/store';
import { setChatMessages } from '../../../store/draftSlice';
import { sendMandatoryQuestionsSubsetRequest } from '../../../store/draftSlice';
import { setViewHeader } from '../../../store/reviewSlice';

import { MessageRole } from '../../../models/chat';
import { ChatMessage } from '../../../models/chat';
import BotChating from '../../shared/botChating/BotChating';

const DraftChat = ({ onReturn }: any) => {
  const [value, setValue] = useState('');
  const currentSequence = useRef<number>(2);
  const [captureLastUserResponse, setCaptureLastUserResponse] =
    useState<string>('');
  const [isCapturedLastUserResponse, setIsCapturedLastUserResponse] =
    useState<boolean>(false);
  const [isChatDisabled, setIsChatDisabled] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const mandatoryQuestions = useSelector(
    (state: RootState) => state.draft.mandatoryQuestionsSubset
  );
  const isLoadingListMandatoryQuestions: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingListMandatoryQuestionsSubset
  );

  useEffect(() => {
    const fetchInitialQuestion = async () => {
      const initialQuestion = 'How can i help you with your drafting needs?';
      const botMessage: ChatMessage = {
        message: initialQuestion,
        role: MessageRole.Assistant,
        id: uuidv4(),
        isChunk: true,
        attached: '',
      };
      dispatch(setChatMessages([...chatMessages, botMessage]));
    };
    fetchInitialQuestion();
  }, []);

  useEffect(() => {
    if (
      !isLoadingListMandatoryQuestions &&
      mandatoryQuestions?.question != null
    ) {
      const botMessage: ChatMessage = {
        message: mandatoryQuestions.question,
        role: MessageRole.Assistant,
        id: uuidv4(),
        isChunk: false,
        attached: '',
      };
      dispatch(setChatMessages([...chatMessages, botMessage]));
    }
  }, [isLoadingListMandatoryQuestions]);

  const onMessageSend = async () => {
    if (isConnected && value.trim() && !isLoadingListMandatoryQuestions) {
      const userMessage: ChatMessage = {
        message: value,
        role: MessageRole.User,
        id: uuidv4(),
        isChunk: false,
        attached: '',
      };
      dispatch(setChatMessages([...chatMessages, userMessage]));
      setValue('');
      await getMandatoryQuestionsInSubset(1, currentSequence.current, value);
      currentSequence.current += 1;
      // if (mandatoryQuestions.is_end && isCapturedLastUserResponse) {
      //   setIsChatDisabled(true);
      //   // TODO: Add logic to generate draft
      //   // TODO: Dispatch action to set hasGeneratedDraft to true
      //   const payload: SendDraftRequestPayload = {
      //     template: null,
      //     chat_history: chatMessages
      //   };
      //   dispatch(sendDraftRequest(payload));
      // } else if (mandatoryQuestions.is_end && !isCapturedLastUserResponse) {
      //   setCaptureLastUserResponse(value);
      //   setIsCapturedLastUserResponse(true);
      // }
    }
  };

  const getMandatoryQuestionsInSubset = async (
    question_id: number,
    current_sequence: number,
    earlier_user_response: string
  ) => {
    const resultAction = await dispatch(
      sendMandatoryQuestionsSubsetRequest({
        question_id,
        current_sequence,
        earlier_user_response,
      })
    );
    if (sendMandatoryQuestionsSubsetRequest.fulfilled.match(resultAction)) {
      console.log('Mandatory Questions:', mandatoryQuestions);
    } else {
      console.error('Failed to get mandatory questions:', resultAction.payload);
    }
  };

  useEffect(() => {
    dispatch(setViewHeader(true));
  }, []);

  return (
    <div>
      <BotChating
        loading={isLoadingListMandatoryQuestions}
        onYes={() => {
          dispatch(setChatMessages([]));
        }}
        onNo={() => {
          dispatch(setChatMessages([]));
          onReturn();
        }}
        onMessageSend={onMessageSend}
        chatMessages={chatMessages.slice(1).map(c => {
          return { ...c };
        })}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default DraftChat;
