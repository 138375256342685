import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage, MessageRole } from '../../../models/chat';
import { sendChatMessage, removeChatMessage } from '../../../store/draftSlice';
import { addAttachMessage } from '../../../store/draftSlice';
import { sendFeedback } from '../../../store/socketSlice';
import { AppDispatch, RootState } from '../../../store/store';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import * as pdfjsLib from 'pdfjs-dist';
import { QCounsel } from 'qanoonisharedpackage';
import { RequestEnum } from '../../../models/base';
import { UserOfflineProcess } from '../../../models/userActivity';
import { UserFeedbackRequest } from '../../../models/feedback';

export const ChatContainer: React.FC = () => {
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const dispatch = useDispatch<AppDispatch>();
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );
  const userOfflineProcess: UserOfflineProcess = useSelector(
    (state: RootState) => state.activity.userOfflineProcess
  );
  const [failure, setfailure] = useState(false);
  // Style for the outer white circle with shadow

  const [isRegenerating, setIsRegenerating] = useState(false);
  const handleRegenerate = async (messageId: string) => {
    console.log('regenerate pressed');
    setIsRegenerating(true);

    // Find the index of the message to regenerate
    const messageIndex = chatMessages.findIndex(msg => msg.id === messageId);
    if (messageIndex === -1) {
      console.error('Message not found');
      setIsRegenerating(false);
      return;
    }

    // Remove the message and all subsequent messages
    for (let i = chatMessages.length - 1; i >= messageIndex; i--) {
      dispatch(removeChatMessage(chatMessages[i].id));
    }

    // Get the last user message before the removed message
    let lastUserMessage = '';
    for (let i = messageIndex - 1; i >= 0; i--) {
      if (chatMessages[i].role === MessageRole.User) {
        lastUserMessage = chatMessages[i].message;
        break;
      }
    }

    // Regenerate the response
    try {
      dispatch(
        sendChatMessage({
          newMessage: {
            id: uuidv4(),
            message: lastUserMessage,
            role: MessageRole.User,
            isChunk: false,
            attached: null,
          },
          add_current: false,
        })
      );
    } catch (error) {
      console.error('Error regenerating message:', error);
    }

    setIsRegenerating(false);
  };

  const sendFeedbackGet = (
    feedbackType: 'thumbs_up' | 'thumbs_down',
    feedbackContent: string
  ) => {
    console.log('feedback send function starts', feedbackType);
    const request: UserFeedbackRequest = {
      action: RequestEnum.UserFeedbackRequest,
      feature_type: 'QCouncel chat',
      feature_ref_id: null, //featureRefId,
      feedback: feedbackType,
      trace_id: null, //traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);

    dispatch(sendFeedback(request));
    console.log('Sent FeedbackGetRequest to server', request);
  };

  // Configure pdfjs to not use a worker

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      setLoading(true); // Show spinner when processing starts
      setfailure(false);

      try {
        if (fileExtension === 'txt') {
          // Handle .txt files directly
          const text = await selectedFile.text();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'docx') {
          // Handle .docx files
          const arrayBuffer = await selectedFile.arrayBuffer();
          const zip = new PizZip(arrayBuffer);
          const doc = new Docxtemplater(zip);
          const text = doc.getFullText();
          console.log('attached text:', text);
          setFileContent(text);
        } else if (fileExtension === 'pdf') {
          // Handle .pdf files
          console.log('Processing PDF file');
          const arrayBuffer = await selectedFile.arrayBuffer();
          console.log('arrayBuffer:', arrayBuffer);

          // Convert ArrayBuffer to Uint8Array
          const uint8Array = new Uint8Array(arrayBuffer);

          // Load PDF with pdfjsLib
          const pdf = await pdfjsLib.getDocument({
            data: uint8Array,
            worker: null,
          }).promise;
          console.log('pdf:', pdf);
          let text = '';

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            textContent.items.forEach((item: any) => {
              if ('str' in item) {
                text += item.str + ' ';
              }
            });
          }
          console.log('attached text:', text);
          setFileContent(text);
        } else {
          // Handle unsupported file extensions
          console.log(`Unsupported file type: ${fileExtension}`);

          dispatch(
            addAttachMessage({
              id: uuidv4(),
              message: `Failed to attach ${selectedFile.name}. Supported file types are PDF, DOCX, and TXT`,
              role: MessageRole.Attach,
              isChunk: false,
              attached: null,
            })
          );
          return; // Exit the function if the file type is unsupported
        }

        dispatch(
          addAttachMessage({
            id: uuidv4(),
            message: `Attached ${selectedFile.name}`,
            role: MessageRole.Attach,
            isChunk: false,
            attached: null,
          })
        );
        console.log('attach message sent');
      } catch (error) {
        console.error('Error processing file:', error);
        setfailure(true);
        setLoading(false);
      } finally {
        setLoading(false); // Hide spinner when processing completes
      }
    }
  };

  const onMessageSend = (value: string) => {
    dispatch(
      sendChatMessage({
        newMessage: {
          id: uuidv4(),
          message: value,
          role: MessageRole.User,
          isChunk: false,
          attached: fileContent ? fileContent : null,
        },
        add_current: true,
      })
    );
  };
  console.log('userOfflineprocess', userOfflineProcess);
  return (
    <div>
      <QCounsel
        isSocketReady={isConnected}
        userOfflineProcess={userOfflineProcess}
        chatMessages={chatMessages}
        sendFeedbackGet={sendFeedbackGet}
        onMessageSend={onMessageSend}
        handleFileChange={handleFileChange}
        loading={loading}
        failure={failure}
        setFileContent={setFileContent}
        handleRegenerate={handleRegenerate}
      />
    </div>
  );
};
