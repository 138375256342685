export const ReviewSteps = {
  initial: 1,
  chat: 2,
  review: 3,
  clauses: 4,
  summary: 5,
};
export const ReviewTabs = {
  review: 1,
  summary: 2,
};

export enum HomeTabs {
  Home = 'Home',
  Review = 'Review',
  Draft = 'Draft',
  Global_changes = 'Global Changes',
  Actions = 'actions',
  Summary = 'summary',
}
export const ActionsTabs = {
  draft: 'draft',
  review: 'review',
  globalChanges: 'global changes',
};
